import { Component, OnInit, ChangeDetectionStrategy } from "@angular/core";
import { Select } from "@ngxs/store";
import { Observable } from "rxjs";
import { Group, Notification, User } from "../definition";
import { AppState } from "../stores/app.state";
import { GroupsState } from "../stores/groups.state";

@Component({
    selector: "app-sidebar-profile",
    template: `
        <section *ngIf="user$ | async as user">
            <div class="bg-primary-500 p-normal ">
                <div class="flex items-center justify-between " style="padding-top: env(safe-area-inset-top); ">
                    <ion-menu-toggle [routerLink]="['/tabs/profile']" class="flex-shrink-0 flex justify-center items-center ion-activatable relative overflow-hidden">
                        <img class="mx-auto h-16 w-16 rounded-lg" [src]="user?.avatar" alt="" />
                        <ion-ripple-effect type="bounded"></ion-ripple-effect>
                    </ion-menu-toggle>
                    <div class="w-full ml-normal overflow-hidden">
                        <div class="w-full flex justify-between items-center">
                            <div class="text-xl font-bold text-white truncate mb-1">{{ user.firstname }}</div>
                            <div class="flex justify-end w-24">
                                <ion-menu-toggle>
                                    <button
                                        [routerLink]="['/tabs/profile/notifications']"
                                        type="button"
                                        class="inline-flex items-center p-2 border border-transparent rounded-full text-white bg-primary-500  justify-center relative"
                                    >
                                        <span class="flex h-5 w-5 absolute z-20" style="top: 4px; right: 0px;" *ngIf="(notifications$ | async)?.length">
                                            <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-white opacity-75"></span>
                                            <span class="relative rounded-full h-5 w-5 text-sm font-bold bg-white text-primary-500 flex justify-center items-center">{{
                                                (notifications$ | async).length
                                            }}</span>
                                        </span>
                                        <ion-icon class="text-white h-6 w-6" name="notifications-outline"></ion-icon>
                                    </button>
                                </ion-menu-toggle>
                                <ion-menu-toggle>
                                    <button
                                        [routerLink]="['/tabs/profile/edit']"
                                        type="button"
                                        class="ion-activatable inline-flex items-center p-2 border border-transparent rounded-full text-white bg-primary-500  justify-center relative overflow-hidden"
                                    >
                                        <fa-icon slot="icon-only" class="text-white h-6 w-6" size="lg" [icon]="['fal', 'user-cog']" [fixedWidth]="true"></fa-icon>
                                        <ion-ripple-effect></ion-ripple-effect>
                                    </button>
                                </ion-menu-toggle>
                            </div>
                        </div>
                        <ion-menu-toggle>
                            <div class="ion-activatable relative overflow-hidden cursor-pointer rounded" [routerLink]="['/tabs/profile/status']">
                                <div class="text-xs font-medium text-gray-300">{{ "Status" | translate }}</div>
                                <div class="text-sm font-medium text-gray-100 truncate" *ngIf="user.status_message">{{ user.status_message }}</div>
                                <div class="text-sm font-medium text-gray-100 truncate" *ngIf="!user.status_message">{{ "Status bearbeiten" | translate }}</div>
                                <ion-ripple-effect></ion-ripple-effect>
                            </div>
                        </ion-menu-toggle>
                    </div>
                </div>
            </div>
        </section>
        <div class="flex  px-normal py-half">
            <ion-menu-toggle>
                <div class="flex flex-col pr-half mr-normal" [routerLink]="['/tabs/chats']" [queryParams]="{ view: 'friends' }">
                    <span class="text-medium-500 text-xs sm:text-sm">Kontakte</span>
                    <span class="text-primary-500 text-xs sm:text-base md:text-xl font-bold text-right"> {{ (friends$ | async)?.length }}</span>
                </div>
            </ion-menu-toggle>

            <ion-menu-toggle>
                <div class="flex flex-col pr-half ng-star-inserted" [routerLink]="['/tabs/groups']" [queryParams]="{ view: 'groups' }">
                    <span class="text-medium-500 text-xs sm:text-sm ng-star-inserted">Gruppen</span>
                    <span class="text-primary-500 text-xs sm:text-base md:text-xl font-bold text-right">{{ (userGroups$ | async)?.length }}</span>
                </div>
            </ion-menu-toggle>
        </div>
    `,

    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarProfileComponent implements OnInit {
    @Select(AppState.user) user$: Observable<User>;
    @Select(GroupsState.userGroups) userGroups$: Observable<Group[]>;
    @Select(AppState.unreadNotifications) notifications$: Observable<Notification[]>;
    @Select(AppState.friends) friends$: Observable<User[]>;
    constructor() {}

    ngOnInit(): void {}
}
