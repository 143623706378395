<ng-progress color="#fff" debounceTime="200"></ng-progress>

<ion-app>
  <ion-menu contentId="main-content">
    <ion-content color="white">
      <app-sidebar-profile></app-sidebar-profile>
      <ion-menu-toggle>
        <ion-item [routerLink]="['/tabs/dashboard']" button detail="true" lines="none">
          <div slot="start">
            <fa-icon class="text-primary-500" [icon]="['fal', 'ywc']" size="lg" [fixedWidth]="true"></fa-icon>
          </div>
          <ion-label class="ion-text-wrap">
            <div class="text-lg">{{ "Start" | translate }}</div>
          </ion-label>
        </ion-item>
      </ion-menu-toggle>
      <ion-menu-toggle>
        <ion-item [routerLink]="['/tabs/explore']" button detail="true" lines="none">
          <div slot="start">
            <fa-icon class="text-primary-500" [icon]="['fal', 'user-check']" size="lg" [fixedWidth]="true"></fa-icon>
          </div>
          <ion-label class="ion-text-wrap">
            <div class="text-lg">{{ "Vorschläge" | translate }}</div>
          </ion-label>
        </ion-item>
      </ion-menu-toggle>
      <ion-menu-toggle>
        <ion-item [routerLink]="['/tabs/chats']" button detail="true" lines="none">
          <div slot="start">
            <fa-icon class="text-primary-500" [icon]="['fal', 'comments']" size="lg" [fixedWidth]="true"></fa-icon>
          </div>
          <ion-label class="ion-text-wrap">
            <div class="text-lg">{{ "Chats" | translate }}</div>
          </ion-label>
        </ion-item>
      </ion-menu-toggle>
      <ion-menu-toggle>
        <ion-item [routerLink]="['/tabs/groups']" button detail="true" lines="none">
          <div slot="start">
            <fa-icon class="text-primary-500" [icon]="['fal', 'users']" size="lg" [fixedWidth]="true"></fa-icon>
          </div>
          <ion-label class="ion-text-wrap">
            <div class="text-lg">{{ "Gruppen" | translate }}</div>
          </ion-label>
        </ion-item>
      </ion-menu-toggle>
      <ion-menu-toggle>
        <ion-item [routerLink]="['/my-info']" button detail="true" lines="none">
          <div slot="start">
            <fa-icon class="text-primary-500" [icon]="['fal', 'hand-receiving']" size="lg" [fixedWidth]="true"></fa-icon>
          </div>
          <ion-label class="ion-text-wrap">
            <div class="text-lg">{{ "Entdecken" | translate }}</div>
          </ion-label>
        </ion-item>
      </ion-menu-toggle>
      <ion-menu-toggle>
        <ion-item [routerLink]="['/tags']" button detail="true" lines="none">
          <div slot="start">
            <fa-icon class="text-primary-500" [icon]="['fal', 'tags']" size="lg" [fixedWidth]="true"></fa-icon>
          </div>
          <ion-label class="ion-text-wrap">
            <div class="text-lg">{{ "Themen" | translate }}</div>
          </ion-label>
        </ion-item>
      </ion-menu-toggle>
      <!--
      <ion-menu-toggle>
        <ion-item [routerLink]="['/informations']" [queryParams]="{ view: 'coaches' }" button detail="true" lines="none">
          <div slot="start">
            <fa-icon class="text-primary-500" [icon]="['fal', 'comment-alt-medical']" size="lg" [fixedWidth]="true"></fa-icon>
          </div>
          <ion-label class="ion-text-wrap">
            <div class="text-lg">YES!COACHES</div>
          </ion-label>
        </ion-item>
      </ion-menu-toggle>
      -->
      <ion-menu-toggle>
        <ion-item [routerLink]="['/informations']" [queryParams]="{ view: 'callin' }" button detail="true" lines="none">
          <div slot="start">
            <fa-icon class="text-primary-500" [icon]="['fal', 'video']" size="lg" [fixedWidth]="true"></fa-icon>
          </div>
          <ion-label class="ion-text-wrap">
            <div class="text-lg">{{ "CALL-IN" | translate }}</div>
          </ion-label>
        </ion-item>
      </ion-menu-toggle>
      <ion-menu-toggle>
        <ion-item [routerLink]="['/informations']" [queryParams]="{ view: 'experts' }" button detail="true" lines="none">
          <div slot="start">
            <fa-icon class="text-primary-500" [icon]="['fal', 'user-md']" size="lg" [fixedWidth]="true"></fa-icon>
          </div>
          <ion-label class="ion-text-wrap">
            <div class="text-lg">{{ "Expert*innen" | translate }}</div>
          </ion-label>
        </ion-item>
      </ion-menu-toggle>
      <ion-menu-toggle>
        <ion-item [routerLink]="['/informations']" [queryParams]="{ view: 'info' }" button detail="true" lines="none">
          <div slot="start">
            <fa-icon class="text-primary-500" [icon]="['fal', 'info-circle']" size="lg" [fixedWidth]="true"></fa-icon>
          </div>
          <ion-label class="ion-text-wrap">
            <div class="text-lg">{{ "Die blauen Ratgeber" | translate }}</div>
          </ion-label>
        </ion-item>
      </ion-menu-toggle>
      <!--
      <ion-menu-toggle>
        <ion-item [routerLink]="['/map']" [queryParams]="{ category: 'support' }" button detail="true" lines="none">
          <div slot="start">
            <fa-icon class="text-primary-500" [icon]="['fal', 'hands-helping']" size="lg" [fixedWidth]="true"></fa-icon>
          </div>
          <ion-label class="ion-text-wrap">
            <div class="text-lg">{{ "Selbsthilfegruppen" | translate }}</div>
          </ion-label>
        </ion-item>
      </ion-menu-toggle>
      -->
      <ion-menu-toggle>
        <ion-item [routerLink]="['/map']" [queryParams]="{ category: 'general' }" button detail="true" lines="none">
          <div slot="start">
            <fa-icon class="text-primary-500" [icon]="['fal', 'street-view']" size="lg" [fixedWidth]="true"></fa-icon>
          </div>
          <ion-label class="ion-text-wrap">
            <div class="text-lg">{{ "Hilfe vor Ort" | translate }}</div>
          </ion-label>
        </ion-item>
      </ion-menu-toggle>

      <ion-menu-toggle>
        <ion-item style="--min-height: 0px" (click)="feedback()" detail="false" lines="none" class="ion-no-padding">
          <ion-label color="primary" class="mx-normal my-half">
            <small>{{ "Feedback & Hilfe" | translate }}</small>
          </ion-label>
        </ion-item>
      </ion-menu-toggle>

      <ion-menu-toggle>
        <ion-item style="--min-height: 0px" href="https://yeswecan-cer.org/wir" target="_blank" detail="false" lines="none" class="ion-no-padding">
          <ion-label color="primary" class="mx-normal my-half">
            <small>{{ "Über yeswecan!cer" | translate }}</small>
          </ion-label>
        </ion-item>
      </ion-menu-toggle>
      <ion-menu-toggle>
        <ion-item style="--min-height: 0px" href="https://yeswecan-cer.org/impressum/" target="_blank" detail="false" lines="none" class="ion-no-padding">
          <ion-label color="primary" class="mx-normal my-half">
            <small>{{ "Impressum" | translate }}</small>
          </ion-label>
        </ion-item>
      </ion-menu-toggle>
      <ion-menu-toggle>
        <ion-item style="--min-height: 0px" href="https://www.yeswecan-cer.org/agb-yes-app/" target="_blank" detail="false" lines="none" class="ion-no-padding">
          <ion-label color="primary" class="mx-normal my-half">
            <small>{{ "AGB" | translate }}</small>
          </ion-label>
        </ion-item>
      </ion-menu-toggle>
      <ion-menu-toggle>
        <ion-item style="--min-height: 0px" [routerLink]="['/privacy']" detail="false" lines="none" class="ion-no-padding">
          <ion-label color="primary" class="mx-normal my-half">
            <small>{{ "Datenschutz" | translate }}</small>
          </ion-label>
        </ion-item>
      </ion-menu-toggle>
      <div class="text-right text-xs text-medium-500 px-3">Version: {{ meta.VERSION }} ({{ meta.BUILD }} - {{ meta.ENV }})</div>
    </ion-content>
  </ion-menu>
  <ion-router-outlet id="main-content"></ion-router-outlet>
</ion-app>
