import { LatLng } from "@agm/core";
import { environment } from "src/environments/environment";

export const APPMeta = {
    VERSION: "3.5.0",
    BUILD: "350001",
    ENV: environment.name,
};

export interface AppError {
    exception: string;
}
export enum AppExceptions {
    UserBlockedException = "UserBlockedException",
}

export enum GroupType {
    Public = "public",
    Private = "private",
}
export enum UserType {
    Patient = "patient",
    Relative = "relative",
}
export enum GroupRole {
    Admin = "admin",
    Member = "member",
}
export enum GroupStatus {
    Approved = "approved",
    Pending = "pending",
    Rejected = "rejected",
}

export interface Disease {
    id: number;
    title: string;
    titlelong?: string;
}
export interface Information {
    id: number;
    title: string;
    subtitle: string;
    url: string;
    image: string;
}

export interface Permission {
    delete?: boolean;
    update?: boolean;
}

export interface Privacy {
    id: number;
    content: string;
    published_at: string;
}
export interface User {
    id?: number;
    uuid?: string;
    password?: string;
    api_token?: string;
    email_verified_at?: string;
    privacy_at?: string;
    type: string;
    avatar: string;
    avatar_thumb: string;
    email?: string;
    email_confirmation?: string;
    emails?: any;
    badge_text?: string;
    title?: string;
    firstname: string;
    lastname?: string;
    status_message?: string;
    age: number;
    birthday: string;
    about: string;
    mystory?: string;
    expertise?: string;
    zipcode: string;
    country: string;
    gender: string;
    stage?: number;
    yeardiagnosed?: number;
    disease_id?: number;
    disease?: string;
    location: { lat: number; lng: number };
    tags: Tag[];

    terms?: boolean;
    privacy?: boolean;
    // Gruppen
    role?: GroupRole;
    status?: GroupRole;

    // Experten haben zusätzliche ihre Admin Gruppen im gepäck
    groups?: Group[];
}

export interface Message {
    group: number;
    sender: User;
    receiver: User;
    body: string;
    type: string;
    created_at: string;
    read_at: string;
}

export interface PaginationMeta {
    current_page: number;
    from: number;
    last_page: number;
    path: string;
    per_page: number;
    to: number;
    total: number;
}
export interface PaginationResult<T> {
    data: T;
    meta: PaginationMeta;
}
export interface Conversation {
    data: Message[];
    meta: PaginationMeta;
}

export interface MessageRequest {
    user: Partial<User>;
    body: string;
    type: string;
}

export interface GroupStoreRequest {
    title: string;
    short_description: string;
    netiquette: boolean;
    disease_id: number;
    category_id: number;
    street: string;
    city: string;
    country: string;
    website: string;
    image_code: string;
}

export interface Upload {
    id: number;
    code: string;
    url: string;
}

export interface LoginRequest {
    email: string;
    password: string;
}
export interface ResetPasswordRequest {
    email: string;
    password: string;
    password_confirmation: string;
    token: string;
}

export interface PushToken {
    user_id: number;
    type: string;
    token: string;
}

export interface Notification {
    id: number;
    read_at: string;
    title: string;
    body: string;
    icon: string;
    url: string;
    ago: string;
}
export interface NotificationSetting {
    label: string;
    notification: string;
    channels: string[];
}

export interface OnboardingUser {
    firstname: string;
    lastname: string;
    privacy: boolean;
    terms: boolean;
    email: string;
    email_confirmation?: string;
    emails?: any;
    password: string;
    image_code?: string;
    type: string;
    birthday: string;
    disease_id: number;
    stage: number;

    // optioal
    status_message?: string;
    gender?: string;
}

export interface ExploreRequest {
    disease_id?: number;
    age_range?: number;
    distance?: number;
    gender?: string;
    user_type?: string;
    page?: number;
}

export interface GroupsGetRequest {
    disease_id: number;
    category_id: number;
    page: number;
}

export interface SupportGroupIndexRequest {
    category: string;
    q: string;
    center: LatLng;
}
export interface SupportGroupRequest {
    category: string;
    id: string;
}
export interface SupportGroup {
    type?: string;
    id: string;
    createdAt?: string;
    updatedAt?: string;

    image?: string;
    title: string;
    subtitle?: string;
    description?: string;

    city: string;
    disease?: string | null;
    email?: string;
    phone?: string;
    street: string;
    url?: string;
    zipCode: string;
    coordinates: { lat: number; lng: number };
}

export enum GroupsPageMode {
    MAP = "map",
    LIST = "list",
}
export enum GroupsPageCategory {
    GENERAL = "general",
    SUPPORT = "support",
}

export interface Group {
    id: number;
    disease: Disease;
    category: GroupCategory;
    title: string;
    type: string;
    short_description: string;
    long_description: string;
    member_count: number;
    posts_count: number;
    created_at: string;
    image: string;
    image_thumb: string;
    avatar_thumb: string;
    location?: { lat: number; lng: number };
    street: string;
    city: string;
    zipcode: string;
    country: string;
    website: string;
    website_name: string;
    posts?: Post[];
    postsPaginationMeta?: PaginationMeta;
    members?: User[];
    membersPaginationMeta?: PaginationMeta;
    member_requests?: User[];
    is_member?: boolean;
    is_admin?: boolean;
    is_member_pending?: boolean;
    tags: Tag[];
}

export interface GroupCategory {
    id: number;
    title: string;
}
export interface Post {
    id: number;
    threading: boolean;
    linkedPosts: Post[];
    group: Group;
    title: string;
    type?: string;
    short_description: string;
    long_description: string;
    image: string;
    image_thumb: string;
    created_at: string;
    user: User;
    comments: Comment[];
    comments_count: number;
    ago?: string;
    meta?: any;

    commentsPaginationMeta?: PaginationMeta;
}

export interface Comment {
    id: number;
    post: Post;
    user: User;
    ago: string;
    content: string;
    created_at: string;
    can?: Permission;
    comments: Comment[];
}

export interface UserIDParams {
    value: string;
}

export interface LogEventParams {
    name: string;
    params?: object;
}

export interface Banner {
    id: number;
    title: string;
    highlight: string;
    url: string;
    text: string;
    image: string;
}

export interface Tag {
    id: number;
    name: string;
    description: string;
    svg: string;
    thumb_image: string;
    square_image: string;
    detail_image: string;
    subscribed: boolean;
    groups: Group[];
    posts: Post[];
    user_posts: Post[];
    informations: Information[];
}
