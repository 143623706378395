import { Component } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { SwUpdate } from "@angular/service-worker";
import { Capacitor } from "@capacitor/core";
import { Config, MenuController, Platform, ToastController } from "@ionic/angular";
import { NgProgress } from "@ngx-progressbar/core";
import { TranslateService } from "@ngx-translate/core";
import { interval } from "rxjs";
import { filter } from "rxjs/operators";

import { MessagingService } from "./services/messaging.service";
import { SocketService } from "./services/socket.service";
import "@capacitor-community/firebase-analytics";
import { TrackingService } from "./services/tracking.service";
import { APPMeta } from "./definition";
import { Store } from "@ngxs/store";
import { AppState as LocalAppState } from "./stores/app.state";

import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";

import { StatusBar, Style } from "@capacitor/status-bar";
import { Keyboard, KeyboardResize } from "@capacitor/keyboard";
import { SplashScreen } from "@capacitor/splash-screen";
import { App, AppState } from "@capacitor/app";
import { PushNotifications } from "@capacitor/push-notifications";
import { FirebaseDynamicLinks } from "@pantrist/capacitor-firebase-dynamic-links";

declare let Userback: any;
declare const __sizzy;
@Component({
    selector: "app-root",
    templateUrl: "app.component.html",
})
export class AppComponent {
    meta = APPMeta;
    constructor(
        private translate: TranslateService,
        private platform: Platform,
        private ngProgress: NgProgress,
        private swUpdate: SwUpdate,
        private toastController: ToastController,
        private messagingService: MessagingService,
        private socketService: SocketService,
        private tracking: TrackingService,
        private router: Router,
        private config: Config,
        private store: Store,
        private menu: MenuController
    ) {
        this.tracking.init();
        this.messagingService.init();
        this.socketService.init();
        this.initializeApp();
    }

    async initializeApp() {
        if (typeof __sizzy !== "undefined" && (this.platform.is("ios") || this.platform.is("android"))) {
            const root = document.documentElement;
            root.style.setProperty("--ion-safe-area-top", "50px");
            root.style.setProperty("--ion-safe-area-bottom", "20px");
        }
        if (this.swUpdate.isEnabled) {
            interval(60 * 1000).subscribe(() => this.swUpdate.checkForUpdate());
        }
        if (this.translate.getBrowserLang() !== "de") {
            this.translate.setDefaultLang("en");
            this.translate.use(this.translate.getBrowserLang());
        } else {
            this.translate.use("de");
        }

        if (Capacitor.isPluginAvailable("App")) {
            App.addListener("appUrlOpen", (data: any) => {
                if (data && data.url) {
                    const url = new URL(data.url);
                    console.log(url);
                    console.log("App opened with URL: " + url.pathname, url.search);
                    setTimeout(() => {
                        this.router
                            .navigateByUrl(url.pathname + url.search)
                            .then((data) => console.log("redirect"))
                            .catch((e) => {
                                console.log("Route not found, redirection stopped with no error raised");
                            });
                    }, 1000);
                }
            });
            FirebaseDynamicLinks.addListener("deepLinkOpen", (data) => {
                if (data && data.url) {
                    const url = new URL(data.url);
                    console.log(url);
                    console.log("FirebaseDynamicLinks App opened with URL: " + url.pathname, url.search);
                    setTimeout(() => {
                        this.router
                            .navigateByUrl(url.pathname + url.search)
                            .then((data) => console.log("redirect"))
                            .catch((e) => {
                                console.log("Route not found, redirection stopped with no error raised");
                            });
                    }, 1000);
                }
            });
            App.addListener("appStateChange", async (state: AppState) => {
                if (state.isActive) {
                    if (Capacitor.isPluginAvailable("PushNotifications")) {
                        await PushNotifications.removeAllDeliveredNotifications();
                    }
                }
            });
        }

        if (Capacitor.isPluginAvailable("SplashScreen")) {
            SplashScreen.hide();
        }
        if (Capacitor.isPluginAvailable("Keyboard")) {
            try {
                await Keyboard.setAccessoryBarVisible({ isVisible: true });
            } catch (error) {}
        }
        if (Capacitor.isPluginAvailable("StatusBar")) {
            try {
                await StatusBar.setStyle({ style: Style.Dark });
                await StatusBar.setBackgroundColor({
                    color: "#df1583",
                });
            } catch (error) {}
        }
        this.router.events.pipe(filter((e) => e instanceof NavigationEnd)).subscribe((e: NavigationEnd) => {
            FirebaseAnalytics.setScreenName({
                screenName: e.url,
                nameOverride: e.url,
            });
        });

        this.store.select(LocalAppState.user).subscribe((user) => {
            this.menu.enable(user ? true : false);
        });
        this.swUpdate.available.subscribe(async (evt) => {
            await this.swUpdate.activateUpdate();

            const toast = await this.toastController.create({
                message: "Update wurde erfolgreich installiert",
                buttons: [
                    {
                        text: "Neu starten",
                        handler: () => {
                            window.location.reload();
                        },
                    },
                ],
            });
            await toast.present();
        });

        /**
         * Der Pogress balken will patout sich nicht verstecken
         * Dann kommt der Hacker halt mit der Keule :x
         */
        const progressRef = this.ngProgress.ref();
        progressRef.started.subscribe(() => {
            document.querySelector(".ng-progress-bar").classList.add("-active");
        });
        progressRef.completed.subscribe(() => {
            document.querySelector(".ng-progress-bar").classList.remove("-active");
        });
    }

    feedback() {
        Keyboard.setResizeMode({ mode: KeyboardResize.Native });
        const user = this.store.selectSnapshot(LocalAppState.user);

        Userback.email = user.email;
        Userback.name = user.firstname;
        Userback.custom_data = {
            uuid: user.uuid,
        };
        Userback.open();
        Userback.on_close = function () {
            Keyboard.setResizeMode({ mode: KeyboardResize.Ionic });
        };
        Userback.after_send = function () {
            Keyboard.setResizeMode({ mode: KeyboardResize.Ionic });
        };
    }
}
