import { Injectable } from '@angular/core';
import { ImmutableContext } from '@ngxs-labs/immer-adapter';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { append, patch, updateItem } from '@ngxs/store/operators';
import { tap } from 'rxjs/operators';

import {
    Comment,
    Group,
    GroupCategory,
    GroupRole,
    GroupStatus,
    GroupType,
    PaginationMeta,
    PaginationResult,
    Post,
} from '../definition';
import { GroupsService } from '../services/groups.service';
import { AppState } from './app.state';
import {
    AcceptMemberRequest,
    AddGroupAdmin,
    AddGroupMembers,
    ClearGroup,
    ClearGroupPostSearch,
    ClearGroupSearch,
    ClearPost,
    CreateGroup,
    CreatePost,
    DeleteComment,
    DeletePost,
    GetCategories,
    GetComments,
    GetGroupMemberRequests,
    GetGroupMembers,
    GetGroupPosts,
    GetGroups,
    GetStream,
    GetUserGroups,
    GetVideoPosts,
    JoinGroup,
    LeaveGroup,
    PostComment,
    ReceiveComment,
    ReceiveDeletedComment,
    RejectMemberRequest,
    RemoveGroupAdmin,
    RemoveGroupMember,
    RequestGroup,
    SearchGroupPosts,
    SearchGroups,
    SelectGroup,
    SelectPost,
    UpdateGroup,
} from './groups.actions';

export interface GroupsStateModel {
    categories: GroupCategory[];
    userGroups: Group[];
    groups: Group[];
    groupsPaginationMeta?: PaginationMeta;
    group: Group;
    post: Post;
    stream: Post[];
    comments: PaginationResult<Comment[]>;
    videoPosts: PaginationResult<Post[]>;
    streamPaginationMeta?: PaginationMeta;
    filterForm: any;
    groupSearch: string;
    groupPostSearch: string;
}

@State<GroupsStateModel>({
    name: "groups",
    defaults: {
        categories: [],
        userGroups: [],
        groups: [],
        stream: [],
        group: null,
        post: null,
        videoPosts: null,
        comments: { data: [], meta: null },
        filterForm: {
            model: undefined,
            dirty: false,
            status: "",
            errors: {},
        },
        groupSearch: null,
        groupPostSearch: null,
    },
})
@Injectable()
export class GroupsState {
    @Selector()
    static filterForm(state: GroupsStateModel) {
        return state.filterForm?.model;
    }

    @Selector()
    public static groups(state: GroupsStateModel) {
        return state.groups;
    }
    @Selector()
    public static userGroups(state: GroupsStateModel) {
        return state.userGroups;
    }
    @Selector()
    public static categories(state: GroupsStateModel) {
        return state.categories;
    }
    @Selector()
    public static stream(state: GroupsStateModel) {
        return state.stream;
    }

    @Selector()
    public static selectedGroup(state: GroupsStateModel) {
        return state.group;
    }
    @Selector()
    public static selectedPost(state: GroupsStateModel) {
        return state.post;
    }
    @Selector()
    public static comments(state: GroupsStateModel) {
        return state.comments;
    }
    @Selector()
    public static videoPosts(state: GroupsStateModel) {
        return state.videoPosts;
    }

    constructor(private groups: GroupsService, private store: Store) {}

    @Action(GetGroups)
    getGroups({ setState, getState }: StateContext<GroupsStateModel>, action: GetGroups) {
        const currentStage = getState();
        const params = { ...currentStage?.filterForm?.model, page: action.page, q: currentStage.groupSearch, disease_id: action.disease_id };

        return this.groups.groups(params).pipe(
            tap((groups) => {
                if (action.page === 1) {
                    setState(
                        patch({
                            groups: groups.data,
                            groupsPaginationMeta: groups.meta,
                        })
                    );
                } else {
                    setState(
                        patch({
                            groups: append(groups.data),
                            groupsPaginationMeta: groups.meta,
                        })
                    );
                }
            })
        );
    }
    @Action(GetUserGroups)
    @ImmutableContext()
    getUserGroups({ setState }: StateContext<GroupsStateModel>) {
        return this.groups.userGroups().pipe(
            tap((groups) => {
                setState((state: GroupsStateModel) => {
                    state.userGroups = groups;
                    return state;
                });
            })
        );
    }
    @Action(GetStream)
    getStream({ setState }: StateContext<GroupsStateModel>, action: GetStream) {
        return this.groups.stream(action.page).pipe(
            tap((posts) => {
                if (action.page === 1) {
                    setState(
                        patch({
                            stream: posts.data,
                            streamPaginationMeta: posts.meta,
                        })
                    );
                } else {
                    setState(
                        patch({
                            stream: append(posts.data),
                            streamPaginationMeta: posts.meta,
                        })
                    );
                }
            })
        );
    }
    @Action(SelectGroup)
    @ImmutableContext()
    selectGroup({ setState }: StateContext<GroupsStateModel>, action: SelectGroup) {
        return this.groups.group(action.group).pipe(
            tap((group) => {
                setState((state: GroupsStateModel) => {
                    state.group = { ...state.group, ...group };
                    return state;
                });
            })
        );
    }

    @Action(ClearGroup)
    @ImmutableContext()
    clearGroup({ setState }: StateContext<GroupsStateModel>) {
        setState((state: GroupsStateModel) => {
            state.group = null;
            return state;
        });
    }

    @Action(SelectPost)
    @ImmutableContext()
    selectPost({ setState }: StateContext<GroupsStateModel>, action: SelectPost) {
        return this.groups.post(action.group, action.post).pipe(
            tap((post) => {
                setState((state: GroupsStateModel) => {
                    state.post = post;
                    return state;
                });
            })
        );
    }

    @Action(GetComments)
    getComments({ setState }: StateContext<GroupsStateModel>, action: GetComments) {
        return this.groups.comments(action.group, action.post, action.page).pipe(
            tap((comments) => {
                if (action.page === 1) {
                    setState(patch({ comments }));
                } else {
                    setState(
                        patch({
                            comments: patch({
                                data: append(comments.data),
                                meta: comments.meta,
                            }),
                        })
                    );
                }
            })
        );
    }
    @Action(PostComment)
    @ImmutableContext()
    postComment({ setState }: StateContext<GroupsStateModel>, action: PostComment) {
        return this.groups.sendComment(action.group, action.post, action.content, action.comment).pipe(
            tap((comment) => {
                setState((state: GroupsStateModel) => {
                    if (action.comment) {
                        state.comments?.data?.find((i) => i.id === action.comment.id).comments.push(comment);
                    } else {
                        state.comments?.data?.unshift(comment);
                    }

                    return state;
                });
            })
        );
    }
    @Action(ReceiveComment)
    @ImmutableContext()
    receiveComment({ setState }: StateContext<GroupsStateModel>, action: ReceiveComment) {
        setState((state: GroupsStateModel) => {
            state.comments?.data?.unshift(action.comment);
            return state;
        });
    }

    @Action(ClearPost)
    @ImmutableContext()
    clearPost({ setState }: StateContext<GroupsStateModel>) {
        setState((state: GroupsStateModel) => {
            state.post = null;
            state.comments = null;
            return state;
        });
    }

    @Action(GetCategories)
    @ImmutableContext()
    getCategories({ setState }: StateContext<GroupsStateModel>) {
        return this.groups.categories().pipe(
            tap((categories) => {
                setState((state: GroupsStateModel) => {
                    state.categories = categories;
                    return state;
                });
            })
        );
    }
    @Action(CreateGroup)
    @ImmutableContext()
    createGroup({ setState }: StateContext<GroupsStateModel>, action: CreateGroup) {
        return this.groups.create(action.params).pipe(
            tap((group) => {
                setState((state: GroupsStateModel) => {
                    state.group = group;
                    return state;
                });
            })
        );
    }

    @Action(UpdateGroup)
    updateGroup({ setState, getState }: StateContext<GroupsStateModel>, action: UpdateGroup) {
        return this.groups.update(action.group, action.params).pipe(
            tap((group) => {
                setState(
                    patch({
                        groups: updateItem<Group>((item) => item.id === action.group.id, patch(group)),
                        userGroups: updateItem<Group>((item) => item.id === action.group.id, patch(group)),
                    })
                );
                // Beiträge und Member nicht weg patchen
                // Darum das Original nehmen und die Sachen vom Server rüberpatchen
                setState(patch({ group: { ...getState().group, ...group } }));
            })
        );
    }
    @Action(GetVideoPosts)
    @ImmutableContext()
    getVideoPosts({ setState }: StateContext<GroupsStateModel>, action: GetVideoPosts) {
        return this.groups.groupPosts({ id: 1 }, 1, { type: "video" }).pipe(
            tap((posts) => {
                setState((state: GroupsStateModel) => {
                    state.videoPosts = posts;
                    return state;
                });
            })
        );
    }
    @Action(GetGroupPosts)
    getGroupPosts({ setState, getState }: StateContext<GroupsStateModel>, action: GetGroupPosts) {
        if (action.group.type === GroupType.Private && action.group.is_member !== true) {
            return;
        }
        const currentStage = getState();
        action.params.q = currentStage.groupPostSearch;
        return this.groups.groupPosts(action.group, action.page, action.params).pipe(
            tap((posts) => {
                if (action.page === 1) {
                    setState(
                        patch({
                            group: patch({
                                posts: posts.data,
                                postsPaginationMeta: posts.meta,
                            }),
                        })
                    );
                } else {
                    setState(
                        patch({
                            group: patch({
                                posts: append(posts.data),
                                postsPaginationMeta: posts.meta,
                            }),
                        })
                    );
                }
            })
        );
    }
    @Action(GetGroupMembers)
    getGroupMembers({ setState }: StateContext<GroupsStateModel>, action: GetGroupMembers) {
        if (action.group.type === GroupType.Private && action.group.is_member !== true) {
            return;
        }

        return this.groups.groupMembers(action.group, action.page).pipe(
            tap((users) => {
                if (action.page === 1) {
                    setState(
                        patch({
                            group: patch({
                                members: users.data,
                                membersPaginationMeta: users.meta,
                            }),
                        })
                    );
                } else {
                    setState(
                        patch({
                            group: patch({
                                members: append(users.data),
                                membersPaginationMeta: users.meta,
                            }),
                        })
                    );
                }
            })
        );
    }
    @Action(GetGroupMemberRequests)
    @ImmutableContext()
    getGroupMemberRequests({ setState }: StateContext<GroupsStateModel>, action: GetGroupMemberRequests) {
        if (!action.group.is_admin) {
            return;
        }
        return this.groups.groupMemberRequests(action.group).pipe(
            tap((users) => {
                setState((state: GroupsStateModel) => {
                    state.group.member_requests = users;
                    return state;
                });
            })
        );
    }

    @Action(JoinGroup)
    @ImmutableContext()
    joinGroup({ setState }: StateContext<GroupsStateModel>, action: JoinGroup) {
        return this.groups.join(action.group).pipe(
            tap((group) => {
                setState((state: GroupsStateModel) => {
                    state.group = group;
                    state.userGroups.push(group);
                    return state;
                });
            })
        );
    }
    @Action(LeaveGroup)
    @ImmutableContext()
    leaveGroup({ setState, getState }: StateContext<GroupsStateModel>, action: LeaveGroup) {
        const user = this.store.selectSnapshot(AppState.user);

        return this.groups.leave(action.group, user).pipe(
            tap((group) => {
                setState((state: GroupsStateModel) => {
                    state.group = group;
                    state.userGroups = state.userGroups.filter((item) => item.id !== action.group.id);
                    return state;
                });
            })
        );
    }
    @Action(RequestGroup)
    @ImmutableContext()
    requestGroup({ setState }: StateContext<GroupsStateModel>, action: RequestGroup) {
        return this.groups.join(action.group).pipe(
            tap((group) => {
                setState((state: GroupsStateModel) => {
                    state.group = group;
                    return state;
                });
            })
        );
    }
    @Action(RemoveGroupMember)
    @ImmutableContext()
    removeGroupMember({ setState }: StateContext<GroupsStateModel>, action: RemoveGroupMember) {
        return this.groups.leave(action.group, action.user).pipe(
            tap((group) => {
                setState((state: GroupsStateModel) => {
                    state.group.members = state.group.members.filter((item) => item.id !== action.user.id);
                    state.group.member_count--;
                    return state;
                });
            })
        );
    }
    @Action(AddGroupAdmin)
    @ImmutableContext()
    addGroupAdmin({ setState }: StateContext<GroupsStateModel>, action: AddGroupAdmin) {
        return this.groups.addAdmin(action.group, action.user).pipe(
            tap((group) => {
                setState((state: GroupsStateModel) => {
                    state.group.members = state.group.members.map((item) => {
                        if (item.id === action.user.id) {
                            item.role = GroupRole.Admin;
                        }
                        return item;
                    });

                    return state;
                });
            })
        );
    }
    @Action(AddGroupMembers)
    @ImmutableContext()
    addGroupMembers({ setState }: StateContext<GroupsStateModel>, action: AddGroupMembers) {
        return this.groups.addMembers(action.group, action.users).pipe(
            tap((group) => {
                setState((state: GroupsStateModel) => {
                    state.group = { ...state.group, ...group };
                    return state;
                });
            })
        );
    }

    @Action(RemoveGroupAdmin)
    @ImmutableContext()
    removeGroupAdmin({ setState }: StateContext<GroupsStateModel>, action: RemoveGroupAdmin) {
        return this.groups.removeAdmin(action.group, action.user).pipe(
            tap((group) => {
                setState((state: GroupsStateModel) => {
                    state.group.members = state.group.members.map((item) => {
                        if (item.id === action.user.id) {
                            item.role = GroupRole.Member;
                        }
                        return item;
                    });
                    return state;
                });
            })
        );
    }
    @Action(AcceptMemberRequest)
    @ImmutableContext()
    acceptMemberRequest({ setState }: StateContext<GroupsStateModel>, action: AcceptMemberRequest) {
        return this.groups.updateMemberRequest(action.group, action.user, GroupStatus.Approved).pipe(
            tap((group) => {
                setState((state: GroupsStateModel) => {
                    state.group.member_requests = state.group.member_requests.filter((item) => item.id !== action.user.id);
                    state.group.members.push(action.user);
                    state.group.member_count++;
                    return state;
                });
            })
        );
    }
    @Action(RejectMemberRequest)
    @ImmutableContext()
    rejectMemberRequest({ setState }: StateContext<GroupsStateModel>, action: RejectMemberRequest) {
        return this.groups.updateMemberRequest(action.group, action.user, GroupStatus.Rejected).pipe(
            tap((group) => {
                setState((state: GroupsStateModel) => {
                    state.group.member_requests = state.group.member_requests.filter((item) => item.id !== action.user.id);
                    state.group.member_count--;
                    return state;
                });
            })
        );
    }

    @Action(CreatePost)
    @ImmutableContext()
    createPost({ setState }: StateContext<GroupsStateModel>, action: CreatePost) {
        return this.groups.createPost(action.group, action.post).pipe(
            tap((post) => {
                setState((state: GroupsStateModel) => {
                    state.group.posts.unshift(post);
                    state.group.posts_count++;
                    return state;
                });
            })
        );
    }
    @Action(DeletePost)
    @ImmutableContext()
    deletePost({ setState }: StateContext<GroupsStateModel>, action: DeletePost) {
        return this.groups.deletePost(action.group, action.post).pipe(
            tap((post) => {
                setState((state: GroupsStateModel) => {
                    state.group.posts = state.group.posts.filter((item) => item.id !== action.post.id);
                    state.group.posts_count--;
                    return state;
                });
            })
        );
    }
    @Action(DeleteComment)
    @ImmutableContext()
    deleteComment({ setState }: StateContext<GroupsStateModel>, action: DeleteComment) {
        return this.groups.deleteComment(action.group, action.post, action.comment).pipe(
            tap((post) => {
                setState((state: GroupsStateModel) => {
                    state.comments.data = state.comments.data.filter((i) => i.id === action.comment.id);
                    return state;
                });
            })
        );
    }

    @Action(ReceiveDeletedComment)
    @ImmutableContext()
    receiveDeletedComment({ setState }: StateContext<GroupsStateModel>, action: ReceiveDeletedComment) {
        setState((state: GroupsStateModel) => {
            state.comments.data = state.comments.data.filter((item) => item.id !== action.commentId);
            return state;
        });
    }

    @Action(SearchGroups)
    @ImmutableContext()
    searchGroups({ setState }: StateContext<GroupsStateModel>, action: SearchGroups) {
        setState((state: GroupsStateModel) => {
            state.groupSearch = action.q;
            return state;
        });
    }
    @Action(SearchGroupPosts)
    @ImmutableContext()
    searchGroupPosts({ setState }: StateContext<GroupsStateModel>, action: SearchGroupPosts) {
        setState((state: GroupsStateModel) => {
            state.groupPostSearch = action.q;
            return state;
        });
    }
    @Action(ClearGroupSearch)
    @ImmutableContext()
    clearGroupSearch({ setState }: StateContext<GroupsStateModel>, action: ClearGroupSearch) {
        setState((state: GroupsStateModel) => {
            state.groupSearch = null;
            return state;
        });
    }
    @Action(ClearGroupPostSearch)
    @ImmutableContext()
    clearGroupPostSearch({ setState }: StateContext<GroupsStateModel>, action: ClearGroupPostSearch) {
        setState((state: GroupsStateModel) => {
            state.groupPostSearch = null;
            return state;
        });
    }
}
