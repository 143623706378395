import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { delayWhen, map } from "rxjs/operators";

import {
    LoginRequest,
    User,
    ExploreRequest,
    Disease,
    Information,
    Privacy,
    ResetPasswordRequest,
    Notification,
    NotificationSetting,
    PaginationResult,
    OnboardingUser,
} from "../definition";

import { AppLoaderService } from "./app-loader.service";
import { BaseService } from "./base.service";
import { UrlSerializer, Router, ActivatedRoute } from "@angular/router";
import { MessagingService } from "./messaging.service";

@Injectable({
    providedIn: "root",
})
export class UserService {
    constructor(private baseService: BaseService, private appLoader: AppLoaderService, private serializer: UrlSerializer, private router: Router) {}
    register(params: OnboardingUser): Observable<User> {
        return this.baseService.post("register", params).pipe(
            map((response) => response.data),
            delayWhen((user) => {
                return this.appLoader.updateApiToken(user.api_token);
            })
        );
    }
    verifyEmail(params: any) {
        return this.baseService.post("verify", params);
    }
    sendEmailVerification(): Observable<User> {
        return this.baseService.post("profile/send-email-verification", null);
    }
    sendPasswordReset(email: string): Observable<User> {
        return this.baseService.post("password/email", { email }).pipe(map((response) => response.data));
    }
    resetPassword(params: ResetPasswordRequest): Observable<User> {
        return this.baseService.post("password/reset", params).pipe(map((response) => response.data));
    }

    login(params: LoginRequest) {
        return this.baseService.post("login", params).pipe(
            map((response) => response.data),
            delayWhen((user) => {
                return this.appLoader.updateApiToken(user.api_token);
            })
        );
    }
    update(params: Partial<OnboardingUser>): Observable<User> {
        return this.baseService.post("profile", params).pipe(map((response) => response.data));
    }
    search(q: string): Observable<User[]> {
        return this.baseService.get(`users?q=${q}`).pipe(map((response) => response.data));
    }

    block(user: User): Observable<User> {
        return this.baseService.post(`users/${user.id}/block`, null).pipe(map((response) => response.data));
    }
    report(reportable_id: string | number, reportable_type: string, comment: string): Observable<User> {
        return this.baseService.post(`reports`, { comment, reportable_id, reportable_type }).pipe(map((response) => response.data));
    }

    diseases(): Observable<Disease[]> {
        return this.baseService.get("diseases").pipe(map((response) => response.data));
    }
    notifications(page: number = 1, unread: boolean = true): Observable<PaginationResult<Notification[]>> {
        return this.baseService.get(`notifications?unread=${unread ? "1" : "0"}`);
    }
    notificationsMarkAsRead(notification: Notification = null): Observable<PaginationResult<Notification[]>> {
        if (notification === null) {
            return this.baseService.post("notifications", null);
        } else {
            return this.baseService.put("notifications/" + notification.id, null);
        }
    }
    notificationSettings(): Observable<NotificationSetting[]> {
        return this.baseService.get("notification-settings").pipe(map((response) => response.data));
    }
    updateNotificationSettings(settings: NotificationSetting[]): Observable<NotificationSetting[]> {
        return this.baseService.post("notification-settings", settings).pipe(map((response) => response.data));
    }

    informations(query: string = null, diseaseId: number = null): Observable<Information[]> {
        let queryUrl = "?";
        if (query) {
            queryUrl += "&q=" + query;
        }
        if (diseaseId) {
            queryUrl += "&disease_id=" + diseaseId;
        }
        return this.baseService.get("informations" + queryUrl).pipe(map((response) => response.data));
    }
    experts(): Observable<User[]> {
        return this.baseService.get("experts").pipe(map((response) => response.data));
    }

    friends(): Observable<User[]> {
        return this.baseService.get("profile/friends").pipe(map((response) => response.data));
    }

    explore(params: ExploreRequest): Observable<User[]> {
        const tree = this.router.createUrlTree([], { queryParams: params });
        const url = this.serializer.serialize(tree).split("?");
        const queryParams = url.length === 2 ? url[1] : "";

        return this.baseService.get("explore?" + queryParams).pipe(map((response) => response.data));
    }
    friendRequest(): Observable<User[]> {
        return this.baseService.get("profile/friend-requests").pipe(map((response) => response.data));
    }
    favorites(): Observable<User[]> {
        return this.baseService.get("profile/favorites").pipe(map((response) => response.data));
    }
    addFavorite(user: User): Observable<User> {
        return this.baseService.post("users/" + user.id + "/favorite", null).pipe(map((response) => response.data));
    }
    removeFavorite(user: User): Observable<User> {
        return this.baseService.delete("users/" + user.id + "/favorite").pipe(map((response) => response.data));
    }
    addFriend(user: User): Observable<User> {
        return this.baseService.post(`profile/friends/${user.id}`, null).pipe(map((response) => response.data));
    }
    acceptFriend(user: User): Observable<User> {
        return this.baseService.post(`profile/friends/${user.id}/accept`, null).pipe(map((response) => response.data));
    }
    denyFriend(user: User): Observable<User> {
        return this.baseService.post(`profile/friends/${user.id}/reject`, null).pipe(map((response) => response.data));
    }

    user(): Observable<User> {
        return this.baseService.get("profile").pipe(map((response) => response.data));
    }
    delete(): Observable<any> {
        return this.baseService.delete("profile").pipe(
            delayWhen((user) => {
                return this.appLoader.updateApiToken(null);
            })
        );
    }
    privacy(): Observable<Privacy> {
        return this.baseService.get("privacies").pipe(map((response) => response.data));
    }
    acceptPrivacy(): Observable<User> {
        return this.baseService.post("privacies", null).pipe(map((response) => response.data));
    }

    userByUuid(uuid: string): Observable<User> {
        return this.baseService.get("users/" + uuid).pipe(map((response) => response.data));
    }
    logout() {
        return this.appLoader.updateApiToken(null);
    }
}
