import { AgmCoreModule } from '@agm/core';
import { registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import lcoaleDe from '@angular/common/locales/de';
import lcoaleEn from '@angular/common/locales/en';
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import Bugsnag, { Event } from '@bugsnag/js';
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
    faBell,
    faCalendar,
    faCameraAlt,
    faCheck,
    faCheckCircle,
    faCommentAltMedical,
    faCommentDots,
    faComments,
    faEdit,
    faEllipsisH,
    faEllipsisV,
    faFilter,
    faHandReceiving,
    faHandsHelping,
    faInfoCircle,
    faLink,
    faList,
    faMapMarkedAlt,
    faMapMarkerAlt,
    faMars,
    faPaperPlane,
    faPhoneVolume,
    faSearch,
    faStream,
    faStreetView,
    faTags,
    faTimes,
    faUserCheck,
    faUserCircle,
    faUserCog,
    faUserFriends,
    faUserMd,
    faUserPlus,
    faUsers,
    faVenus,
    faVideo,
} from '@fortawesome/pro-light-svg-icons';
import {
    faEnvelope,
    faFilter as faFilterSolid,
    faGlobe,
    faMapMarker,
    faPhone,
    faStar,
} from '@fortawesome/pro-solid-svg-icons';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { NgProgressModule } from '@ngx-progressbar/core';
import { NgProgressHttpModule } from '@ngx-progressbar/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxsEmitPluginModule } from '@ngxs-labs/emitter';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsModule } from '@ngxs/store';
import { DynamicModule } from 'ng-dynamic-component';
import { MarkdownModule, MarkedOptions } from 'ngx-markdown';
import { environment } from 'src/environments/environment';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ComponentsModule } from './components/components.module';
import { APPMeta } from './definition';
import { AppLoaderService } from './services/app-loader.service';
import { AppState } from './stores/app.state';
import { ChatState } from './stores/chat.state';
import { GroupsState } from './stores/groups.state';
import { OnboardingState } from './stores/onboarding.state';
import { TagsState } from './stores/tags.state';

registerLocaleData(lcoaleDe);
registerLocaleData(lcoaleEn);

Bugsnag.start({
    apiKey: "8ade7b006127dffceba06e3bd524f2a2",
    releaseStage: environment.production ? "production" : "development",
    enabledReleaseStages: ["production"],
    appVersion: APPMeta.BUILD,
    collectUserIp: false,
    onError: (event: Event) => {
        // Bugsnag soll Custom Exception und HTTP Fehler ignorieren
        // Bissel doof aber anders bekomme ich das gerade nicht hin
        if (event.errors[0].errorClass === "[no error class]" || event.errors[0].errorClass === "HttpErrorResponse") {
            return false;
        }
    },
});

// create a factory which will return the bugsnag error handler
export function errorHandlerFactory() {
    return new BugsnagErrorHandler();
}

export function init(appLoadService: AppLoaderService) {
    return () => appLoadService.init();
}
// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

const faYWC = {
    prefix: "fal",
    iconName: "ywc",
    icon: [
        24,
        24,
        [],
        "e001",
        "M22.13 6.81c0.015-0.086 0.024-0.184 0.024-0.285s-0.009-0.199-0.025-0.295l0.001 0.010-0.37-0.89c-0.116-0.052-0.251-0.090-0.392-0.109l-0.008-0.001-7 3.27-3.77 2.090-3.12-1.8c-0.54-0.3-2-1.21-4.47-2.7-0.185 0.079-0.344 0.184-0.481 0.311l0.001-0.001-0.52 0.78c0.020 0.145 0.059 0.277 0.114 0.399l-0.004-0.009 6.31 4.26-2.42 1.31-4.070 2.85c-0.090 0-0.090 0.17 0 0.38 0.5 0.29 0.78 0.44 0.84 0.46s0.41 0.24 1 0.37c0.18-0.080 0.72-0.4 1.63-1s2-1.2 2.93-1.73l2.29-1.35 6.13 3.55 4.52 2c0.080 0.060 0.19 0 0.32-0.19 0.009-0.142 0.015-0.308 0.015-0.475s-0.005-0.333-0.016-0.497l0.001 0.022c0.002-0.035 0.003-0.076 0.003-0.118 0-0.32-0.071-0.623-0.199-0.895l0.005 0.013c-0.16-0.11-0.71-0.42-1.66-0.9s-2-1.090-3-1.64c-1.1-0.58-2.36-1.28-3.79-2.070l-0.11-0.060 4.74-2.63q0.8-0.45 4.61-2.44z",
    ],
};

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        IonicModule.forRoot({
            swipeBackEnabled: false,
            backButtonText: "Zurück"
        }),
        AppRoutingModule,
        NgxsModule.forRoot([AppState, ChatState, GroupsState, TagsState, OnboardingState], { developmentMode: !environment.production }),
        NgxsEmitPluginModule.forRoot(),
        NgxsFormPluginModule.forRoot(),
        NgxsStoragePluginModule.forRoot(),
        NgxsReduxDevtoolsPluginModule.forRoot({ disabled: environment.production }),
        NgProgressModule.withConfig({}),
        NgProgressHttpModule.withConfig({}),
        FontAwesomeModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        AgmCoreModule.forRoot({
            apiKey: "AIzaSyB7RWWVe97VZS0LniyqoFyGLaY7H_HxGSM",
        }),
        MarkdownModule.forRoot({
            markedOptions: {
                provide: MarkedOptions,
                useValue: {
                    gfm: true,
                    tables: true,
                    breaks: true,
                    pedantic: false,
                    sanitize: false,
                    smartLists: true,
                    smartypants: false,
                },
            },
        }),
        AngularFireMessagingModule,
        AngularFireModule.initializeApp(environment.firebase),
        ComponentsModule,
        DynamicModule,
        ServiceWorkerModule.register("ngsw-worker.js", { enabled: environment.production }),
    ],
    providers: [
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: APP_INITIALIZER, useFactory: init, deps: [AppLoaderService], multi: true },
        { provide: ErrorHandler, useFactory: errorHandlerFactory },
        { provide: LOCALE_ID, useValue: "de" },
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor(library: FaIconLibrary) {
        library.addIcons(
            faUserCog,
            faUserCheck,
            faPhoneVolume,
            faStreetView,
            faVideo,
            faFilter,
            faHandsHelping,
            faSearch,
            faTags,
            faComments,
            faUserPlus,
            faStar,
            faFilterSolid,
            faMapMarkedAlt,
            faList,
            faInfoCircle,
            faMapMarker,
            faPhone,
            faEnvelope,
            faGlobe,
            faCommentDots,
            faHandReceiving,
            faUserCircle,
            faEllipsisH,
            faEllipsisV,
            faEdit,
            faCheckCircle,
            faStar,
            faMars,
            faBell,
            faUserFriends,
            faVenus,
            faCheck,
            faTimes,
            faLink,
            faPaperPlane,
            faUsers,
            faStream,
            faMapMarkerAlt,
            faCalendar,
            faCameraAlt,
            faUserMd,
            faCommentAltMedical,
            <any>faYWC
        );
    }
}
