import { CommonModule } from "@angular/common";
import { Directive, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { IonicModule } from "@ionic/angular";
import { TranslateModule } from "@ngx-translate/core";
import { AutosizeModule } from "ngx-autosize";
import { MarkdownModule } from "ngx-markdown";

import { PipesModule } from "../pipes/pipes.module";
import { CardGroupComponent } from "./card-group/card-group.component";
import { CardInviteComponent } from "./card-invite/card-invite.component";
import { CardPostComponent } from "./card-post/card-post.component";
import { CropperComponent } from "./cropper/cropper.component";
import { InputImageComponent } from "./input-image/input-image.component";
import { ItemUserComponent } from "./item-user/item-user.component";
import { PhotoEditorComponent } from "./photo-editor/photo-editor.component";
import { UserMoreComponent } from "./popover/user-more/user-more.component";
import { ViewAvatarCircularComponent } from "./view-avatar-circular/view-avatar-circular.component";
import { ViewAvatarComponent } from "./view-avatar/view-avatar.component";
import { ViewCommentComponent } from "./view-comment/view-comment.component";
import { ViewEmptyComponent } from "./view-empty/view-empty.component";
import { ViewLoadingComponent } from "./view-loading/view-loading.component";
import { ViewMapInfoComponent } from "./view-map-info/view-map-info.component";
import { ViewMarkdownComponent } from "./view-markdown/view-markdown.component";
import { ViewTutorialComponent } from "./view-tutorial/view-tutorial.component";
import { ViewUserActionsComponent } from "./view-user-actions/view-user-actions.component";
import { ViewUserFavoriteComponent } from "./view-user-favorite/view-user-favorite.component";
import { ViewUserMetaComponent } from "./view-user-meta/view-user-meta.component";
import { WrapperComponent } from "./wrapper/wrapper.component";
import { ControlErrorComponent } from "./control-error/control-error.component";
import { ItemNotificationComponent } from "./item-notification.component";
import { SidebarProfileComponent } from "./sidebar-profile.component";
import { ViewCoachesComponent } from "./view-coaches.component";
import { CardCoachComponent } from "./card-coach.component";
import { ViewExpertsComponent } from "./view-experts.component";
import { CardExpertComponent } from "./card-expert.component";
import { DashboardPostsComponent } from "./dashboard-posts.component";
import { DashboardExploreComponent } from "./dashboard-explore.component";
import { DashboardGroupsComponent } from "./dashboard-groups.component";
import { DashboardBannerComponent } from "./dashboard-banner.component";
import { DashboardHelloComponent } from "./dashboard-hello.component";
import { DashboardCallInComponent } from "./dashboard-call-in.component";
import { DashboardLocationsComponent } from "./dashboard-locations.component";
import { DashboardInformationsComponent } from "./dashboard-informations.component";
import { DashboardTagsComponent } from "./dashboard-tags.component";
import { PostVideoComponent } from "./post-video.component";
import { PostTextComponent } from "./post-text.component";
import { PostCommentsComponent } from "./post-comments.component";
import { PostNestedComponent } from "./post-nested.component";
import { DynamicModule } from "ng-dynamic-component";
import { CommentFormComponent } from "./comment-form.component";
import { DirectivesModule } from "../directives/directives.module";
import { AlertComponent } from './alert.component';
import { OnboardingNameComponent } from './onboarding-name.component';
import { OnboardingTypeComponent } from './onboarding-type.component';
import { OnboardingDiseaseComponent } from './onboarding-disease.component';
import { OnboardingStageComponent } from './onboarding-stage.component';
import { OnboardingBirthdayComponent } from './onboarding-birthday.component';
import { OnboardingLoginComponent } from './onboarding-login.component';
import { OnboardingPhotoComponent } from './onboarding-photo.component';
import { ProfileCheckComponent } from "./profile-check.component";
import { OnboardingGenderComponent } from './onboarding-gender.component';
import { OnboardingTopicsComponent } from './onboarding-topics.component';
import { OnboardingZipComponent } from './onboarding-zip.component';
import { ReportComponent } from './report.component';

@NgModule({
    imports: [
        CommonModule,
        IonicModule,
        ReactiveFormsModule,
        FormsModule,
        FontAwesomeModule,
        TranslateModule,
        RouterModule,
        PipesModule,
        MarkdownModule.forChild(),
        AutosizeModule,
        DynamicModule,
        DirectivesModule,
    ],
    exports: [
        PhotoEditorComponent,
        InputImageComponent,
        ViewAvatarComponent,
        ViewAvatarCircularComponent,
        WrapperComponent,
        ViewLoadingComponent,
        ViewEmptyComponent,
        ViewTutorialComponent,
        CardInviteComponent,
        UserMoreComponent,
        ViewMapInfoComponent,
        ItemUserComponent,
        ViewUserMetaComponent,
        ViewUserFavoriteComponent,
        ViewUserActionsComponent,
        CardPostComponent,
        CardGroupComponent,
        ViewMarkdownComponent,
        CropperComponent,
        ViewCommentComponent,
        ControlErrorComponent,
        ItemNotificationComponent,
        SidebarProfileComponent,
        ViewCoachesComponent,
        CardCoachComponent,
        ViewExpertsComponent,
        CardExpertComponent,
        DashboardPostsComponent,
        DashboardExploreComponent,
        DashboardGroupsComponent,
        DashboardBannerComponent,
        DashboardHelloComponent,
        DashboardCallInComponent,
        DashboardLocationsComponent,
        DashboardInformationsComponent,
        DashboardTagsComponent,
        PostVideoComponent,
        PostTextComponent,
        PostCommentsComponent,
        PostNestedComponent,
        CommentFormComponent,
        AlertComponent,
        OnboardingNameComponent,
        OnboardingTypeComponent,
        OnboardingDiseaseComponent,
        OnboardingStageComponent,
        OnboardingBirthdayComponent,
        OnboardingLoginComponent,
        OnboardingPhotoComponent,
        ProfileCheckComponent,
        OnboardingGenderComponent,
        OnboardingTopicsComponent,
        OnboardingZipComponent,
        ReportComponent,
    ],
    declarations: [
        PhotoEditorComponent,
        InputImageComponent,
        ViewAvatarComponent,
        ViewAvatarCircularComponent,
        WrapperComponent,
        ViewLoadingComponent,
        ViewEmptyComponent,
        ViewTutorialComponent,
        CardInviteComponent,
        UserMoreComponent,
        ViewMapInfoComponent,
        ItemUserComponent,
        ViewUserMetaComponent,
        ViewUserFavoriteComponent,
        ViewUserActionsComponent,
        CardPostComponent,
        CardGroupComponent,
        ViewMarkdownComponent,
        CropperComponent,
        ViewCommentComponent,
        ControlErrorComponent,
        ItemNotificationComponent,
        SidebarProfileComponent,
        ViewCoachesComponent,
        CardCoachComponent,
        ViewExpertsComponent,
        CardExpertComponent,
        DashboardPostsComponent,
        DashboardExploreComponent,
        DashboardGroupsComponent,
        DashboardBannerComponent,
        DashboardHelloComponent,
        DashboardCallInComponent,
        DashboardLocationsComponent,
        DashboardInformationsComponent,
        DashboardTagsComponent,
        PostVideoComponent,
        PostTextComponent,
        PostCommentsComponent,
        PostNestedComponent,
        CommentFormComponent,
        AlertComponent,
        OnboardingNameComponent,
        OnboardingTypeComponent,
        OnboardingDiseaseComponent,
        OnboardingStageComponent,
        OnboardingBirthdayComponent,
        OnboardingLoginComponent,
        OnboardingPhotoComponent,
        ProfileCheckComponent,
        OnboardingGenderComponent,
        OnboardingTopicsComponent,
        OnboardingZipComponent,
        ReportComponent,
    ]
})
export class ComponentsModule {}
