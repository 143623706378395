import { User, Message } from "../definition";

export class GetConversation {
    static readonly type = "[APP] Get Conversations";
    constructor(public user: Partial<User>, public page: number = 1) {}
}
export class DeleteConversation {
    static readonly type = "[APP] Delete Conversations";
    constructor(public user: Partial<User>) {}
}

export class ClearConversation {
    static readonly type = "[APP] Clear Conversation";
    constructor() {}
}
export class GetConversations {
    static readonly type = "[APP] Get all Conversations";
    constructor() {}
}

export class SendMessage {
    static readonly type = "[APP] Send Message";
    constructor(public user: Partial<User>, public body: string, public messageType: string) {}
}

export class MarkAsReadMessage {
    static readonly type = "[APP] Mark as read Message";
    constructor(public user: Partial<User>) {}
}

export class ReceiveMessage {
    static readonly type = "[APP] Receive Message";
    constructor(public message: Message) {}
}
