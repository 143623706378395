import { ImmutableContext } from "@ngxs-labs/immer-adapter";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { append, patch, removeItem } from "@ngxs/store/operators";
import { map, tap } from "rxjs/operators";
import Bugsnag from "@bugsnag/js";
import { Disease, Information, SupportGroup, User, Privacy, NotificationSetting, PaginationMeta, Notification, Banner } from "../definition";

import { MapService } from "../services/map.service";
import { UserService } from "../services/user.service";
import {
    AcceptFriendRequest,
    AddFavorit,
    AddFriend,
    ClearSupportGroups,
    DenyFriendRequest,
    ExploreUsers,
    GetDiseases,
    GetFavorites,
    GetFriendRequests,
    GetFriends,
    GetInformations,
    GetSupportGroup,
    GetSupportGroups,
    GetUser,
    RegisterUser,
    RemoveFavorit,
    SelectUser,
    UpdateUser,
    UserLogin,
    UserLogout,
    ClearSelectedUser,
    ClearSelectedSupportGroup,
    AddFriendRequest,
    BlockUser,
    SearchUsers,
    ClearSearch,
    ReportUser,
    DisableNotifications,
    GetPrivacy,
    AcceptPrivacy,
    SendPasswordResetLink,
    ResetPassword,
    SendEmailVerification,
    VerifyEmail,
    DeleteUser,
    GetNotifications,
    GetNotificationSettings,
    UpdateNotificationSettings,
    UpdateNotificationMarkAsRead,
    GetExperts,
    NewUsers,
} from "./app.action";
import { MessagingService } from "../services/messaging.service";
import { Injectable, Injector } from "@angular/core";
import { TrackingService } from "../services/tracking.service";
import { BaseService } from "../services/base.service";
import { EmitterAction, Receiver } from "@ngxs-labs/emitter";

export interface AppStateModel {
    user: User;
    friends: User[];
    friendRequests: User[];
    privacy: Privacy;
    favorites: User[];
    exploreUsers: User[];
    newUsers: User[];
    searchUsers: User[];
    selectedUser: User;
    supportGroups: SupportGroup[];
    selectedSupportGroup: SupportGroup;

    filterForm: any;

    diseases: Disease[];
    informations: Information[];

    notifications: Notification[];
    notificationsPaginationMeta?: PaginationMeta;
    notificationSettings: NotificationSetting[];

    experts: User[];
    banner: Banner;


}
@State<AppStateModel>({
    name: "app",
    defaults: {
        user: null,
        selectedUser: null,
        privacy: null,
        friends: [],
        friendRequests: [],
        favorites: [],
        exploreUsers: [],
        newUsers: [],
        searchUsers: [],
        supportGroups: [],
        selectedSupportGroup: null,
        diseases: [],
        informations: [],

        filterForm: {
            model: undefined,
            dirty: false,
            status: "",
            errors: {},
        },

        notifications: [],
        notificationsPaginationMeta: null,
        notificationSettings: [],

        experts: [],
        banner: null,
    },
})
@Injectable()
export class AppState {
    private static api: BaseService;

    constructor(private userService: UserService, private mapService: MapService, private messageService: MessagingService, private tracking: TrackingService, injector: Injector) {
        AppState.api = injector.get<BaseService>(BaseService);
    }

    @Selector()
    static filterForm(state: AppStateModel) {
        return state.filterForm?.model;
    }

    @Selector()
    static user(state: AppStateModel) {
        return state.user;
    }
    @Selector()
    static exploreUsers(state: AppStateModel) {
        return state.exploreUsers;
    }

    @Selector()
    static newUsers(state: AppStateModel) {
        return state.newUsers;
    }

    @Selector()
    static friends(state: AppStateModel) {
        return state.friends;
    }
    @Selector()
    static searchUsers(state: AppStateModel) {
        return state.searchUsers;
    }

    @Selector()
    static favorites(state: AppStateModel) {
        return state.favorites;
    }
    @Selector()
    static selectedUser(state: AppStateModel) {
        return state.selectedUser;
    }
    @Selector()
    static supportGroups(state: AppStateModel) {
        return state.supportGroups;
    }
    @Selector()
    static selectedSupportGroup(state: AppStateModel) {
        return state.selectedSupportGroup;
    }
    @Selector()
    static friendRequests(state: AppStateModel) {
        return state.friendRequests;
    }
    @Selector()
    static diseases(state: AppStateModel) {
        return state.diseases;
    }
    @Selector()
    static informations(state: AppStateModel) {
        return state.informations;
    }
    @Selector()
    static privacy(state: AppStateModel) {
        return state.privacy;
    }

    @Selector()
    static notifications(state: AppStateModel) {
        return state.notifications;
    }
    @Selector()
    static unreadNotifications(state: AppStateModel) {
        return state.notifications.filter((i) => i.read_at === null);
    }
    @Selector()
    static notificationSettings(state: AppStateModel) {
        return state.notificationSettings;
    }

    @Selector()
    static experts(state: AppStateModel) {
        return state.experts.filter((i) => i.type === "expert");
    }

    @Selector()
    static banner(state: AppStateModel) {
        return state.banner;
    }

    @Selector()
    static coaches(state: AppStateModel) {
        return state.experts.filter((i) => i.type === "coach");
    }

    @Action(UserLogin)
    @ImmutableContext()
    userLogin({ setState }: StateContext<AppStateModel>, action: UserLogin) {
        return this.userService.login(action.params).pipe(
            tap((user) => {
                setState((state: AppStateModel) => {
                    state.user = user;
                    return state;
                });
            })
        );
    }

    @Action(GetSupportGroups)
    @ImmutableContext()
    getSupportGroups({ setState }: StateContext<AppStateModel>, action: GetSupportGroups) {
        return this.mapService.index(action.params).pipe(
            tap((groups) => {
                setState((state: AppStateModel) => {
                    state.supportGroups = groups;
                    return state;
                });
            })
        );
    }
    @Action(GetSupportGroup)
    @ImmutableContext()
    getSupportGroup({ setState }: StateContext<AppStateModel>, action: GetSupportGroup) {
        return this.mapService.detail(action.params).pipe(
            tap((group) => {
                setState((state: AppStateModel) => {
                    state.selectedSupportGroup = group;
                    return state;
                });
            })
        );
    }
    @Action(ClearSupportGroups)
    @ImmutableContext()
    clearSupportGroups({ setState }: StateContext<AppStateModel>) {
        setState((state: AppStateModel) => {
            state.supportGroups = [];
            return state;
        });
    }
    @Action(ClearSelectedSupportGroup)
    @ImmutableContext()
    clearSelectedSupportGroup({ setState }: StateContext<AppStateModel>) {
        setState((state: AppStateModel) => {
            state.selectedSupportGroup = null;
            return state;
        });
    }

    @Action(DisableNotifications)
    @ImmutableContext()
    async disableNotifications({ setState }: StateContext<AppStateModel>) {
        await this.messageService.disableNotification();
    }

    @Action(UserLogout)
    @ImmutableContext()
    userLogout({ setState }: StateContext<AppStateModel>) {
        return this.userService.logout().pipe(
            tap((user) => {
                setState((state: AppStateModel) => {
                    state.user = null;
                    this.tracking.setUserID(null);
                    return state;
                });
            })
        );
    }
    @Action(GetUser)
    @ImmutableContext()
    getUser({ setState }: StateContext<AppStateModel>) {
        return this.userService.user().pipe(
            tap((user) => {
                setState((state: AppStateModel) => {
                    state.user = user;
                    this.tracking.setUserID(user.uuid);
                    Bugsnag.setUser(user.uuid);

                    return state;
                });
            })
        );
    }
    @Action(DeleteUser)
    @ImmutableContext()
    deleteUser({ setState }: StateContext<AppStateModel>) {
        return this.userService.delete().pipe(
            tap((user) => {
                setState((state: AppStateModel) => {
                    state.user = null;
                    return state;
                });
            })
        );
    }
    @Action(GetPrivacy)
    @ImmutableContext()
    GetPrivacy({ setState }: StateContext<AppStateModel>) {
        return this.userService.privacy().pipe(
            tap((privacy) => {
                setState((state: AppStateModel) => {
                    state.privacy = privacy;
                    return state;
                });
            })
        );
    }
    @Action(AcceptPrivacy)
    @ImmutableContext()
    acceptPrivacy({ setState }: StateContext<AppStateModel>) {
        return this.userService.acceptPrivacy().pipe(
            tap((user) => {
                setState((state: AppStateModel) => {
                    state.user = user;
                    return state;
                });
            })
        );
    }

    @Action(SelectUser)
    @ImmutableContext()
    delectUser({ setState }: StateContext<AppStateModel>, action: SelectUser) {
        return this.userService.userByUuid(action.user.uuid).pipe(
            tap((user) => {
                setState((state: AppStateModel) => {
                    state.selectedUser = user;
                    return state;
                });
            })
        );
    }
    @Action(ClearSelectedUser)
    @ImmutableContext()
    clearSelectedUser({ setState }: StateContext<AppStateModel>, action: SelectUser) {
        setState((state: AppStateModel) => {
            state.selectedUser = null;
            return state;
        });
    }

    @Action(RegisterUser)
    @ImmutableContext()
    registerUser({ setState }: StateContext<AppStateModel>, action: RegisterUser) {
        return this.userService.register(action.params).pipe(
            tap((user) => {
                setState((state: AppStateModel) => {
                    state.user = user;
                    return state;
                });
            })
        );
    }
    @Action(SendEmailVerification)
    @ImmutableContext()
    sendEmailVerification({ setState }: StateContext<AppStateModel>) {
        return this.userService.sendEmailVerification();
    }
    @Action(VerifyEmail)
    @ImmutableContext()
    verifyEmail({ setState }: StateContext<AppStateModel>, action: VerifyEmail) {
        return this.userService.verifyEmail(action.params);
    }

    @Action(SendPasswordResetLink)
    @ImmutableContext()
    sendPasswordResetLink({ setState }: StateContext<AppStateModel>, action: SendPasswordResetLink) {
        return this.userService.sendPasswordReset(action.email);
    }
    @Action(ResetPassword)
    @ImmutableContext()
    resetPassword({ setState }: StateContext<AppStateModel>, action: ResetPassword) {
        return this.userService.resetPassword(action.params);
    }

    @Action(UpdateUser)
    @ImmutableContext()
    updateUser({ setState }: StateContext<AppStateModel>, action: UpdateUser) {
        return this.userService.update(action.params).pipe(
            tap((user) => {
                setState((state: AppStateModel) => {
                    state.user = user;
                    return state;
                });
            })
        );
    }

    @Action(GetDiseases)
    @ImmutableContext()
    getDiseases({ setState }: StateContext<AppStateModel>) {
        return this.userService.diseases().pipe(
            tap((diseases) => {
                setState((state: AppStateModel) => {
                    state.diseases = diseases;
                    return state;
                });
            })
        );
    }

    @Action(GetInformations)
    @ImmutableContext()
    getInformations({ setState }: StateContext<AppStateModel>, action: GetInformations) {
        return this.userService.informations(action.query, action.diseaseId).pipe(
            tap((informations) => {
                setState((state: AppStateModel) => {
                    state.informations = informations;
                    return state;
                });
            })
        );
    }

    @Action(GetFriends)
    @ImmutableContext()
    getFriends({ setState }: StateContext<AppStateModel>) {
        return this.userService.friends().pipe(
            tap((friends) => {
                setState((state: AppStateModel) => {
                    state.friends = friends;
                    return state;
                });
            })
        );
    }
    @Action(GetFriendRequests)
    @ImmutableContext()
    getFriendReqquests({ setState }: StateContext<AppStateModel>) {
        return this.userService.friendRequest().pipe(
            tap((friends) => {
                setState((state: AppStateModel) => {
                    state.friendRequests = friends;
                    return state;
                });
            })
        );
    }
    @Action(AcceptFriendRequest)
    acceptFriendRequest({ setState }: StateContext<AppStateModel>, action: AcceptFriendRequest) {
        return this.userService.acceptFriend(action.user).pipe(
            tap((friend) => {
                setState(
                    patch({
                        friends: append([friend]),
                        friendRequests: removeItem<User>((item) => item.id === action.user.id),
                    })
                );
            })
        );
    }
    @Action(DenyFriendRequest)
    denyFriendRequest({ setState }: StateContext<AppStateModel>, action: AcceptFriendRequest) {
        return this.userService.denyFriend(action.user).pipe(
            tap((friend) => {
                setState(
                    patch({
                        friendRequests: removeItem<User>((item) => item.id === action.user.id),
                    })
                );
            })
        );
    }
    @Action(BlockUser)
    blockUser({ setState }: StateContext<AppStateModel>, action: BlockUser) {
        return this.userService.block(action.user);
    }
    @Action(ReportUser)
    reportUser({ setState }: StateContext<AppStateModel>, action: ReportUser) {
        let mapping = {
            post: "\\App\\Post",
            user: "\\App\\User",
            comment: "\\App\\Comment",
            group: "\\App\\Group",
        };
        return this.userService.report(action.reportableId, mapping[action.reportableType], action.comment);
    }

    @Action(AddFriend)
    @ImmutableContext()
    addFriend({ setState }: StateContext<AppStateModel>, action: AcceptFriendRequest) {
        return this.userService.addFriend(action.user);
    }
    @Action(AddFriendRequest)
    addFriendRequest({ setState }: StateContext<AppStateModel>, action: AddFriendRequest) {
        setState(
            patch({
                friendRequests: append([action.user]),
            })
        );
    }

    @Action(GetFavorites)
    @ImmutableContext()
    getFavorites({ setState }: StateContext<AppStateModel>) {
        return this.userService.favorites().pipe(
            tap((friends) => {
                setState((state: AppStateModel) => {
                    state.favorites = friends;
                    return state;
                });
            })
        );
    }

    @Action(AddFavorit)
    addFavorit({ setState }: StateContext<AppStateModel>, action: AcceptFriendRequest) {
        return this.userService.addFavorite(action.user).pipe(
            tap((friend) => {
                setState(
                    patch({
                        favorites: append([friend]),
                    })
                );
            })
        );
    }
    @Action(RemoveFavorit)
    removeFavorit({ setState }: StateContext<AppStateModel>, action: AcceptFriendRequest) {
        return this.userService.removeFavorite(action.user).pipe(
            tap((friend) => {
                setState(
                    patch({
                        favorites: removeItem<User>((item) => item.id === action.user.id),
                    })
                );
            })
        );
    }
    @Action(NewUsers)
    @ImmutableContext()
    newUsers({ setState, getState }: StateContext<AppStateModel>, action: NewUsers) {
        return this.userService.explore({}).pipe(
            tap((users) => {
                setState((state: AppStateModel) => {
                    state.newUsers = users;
                    return state;
                });
            })
        );
    }
    @Action(ExploreUsers)
    exploreUsers({ setState, getState }: StateContext<AppStateModel>, action: ExploreUsers) {
        const currentStage = getState();
        const params = { ...currentStage.filterForm?.model, page: action.page };

        return this.userService.explore(params).pipe(
            tap((users) => {
                if (action.page === 1) {
                    setState(
                        patch({
                            exploreUsers: users,
                        })
                    );
                } else {
                    setState(
                        patch({
                            exploreUsers: append(users),
                        })
                    );
                }
            })
        );
    }

    @Action(SearchUsers)
    @ImmutableContext()
    searchUsers({ setState }: StateContext<AppStateModel>, action: SearchUsers) {
        return this.userService.search(action.q).pipe(
            tap((users) => {
                setState((state: AppStateModel) => {
                    state.searchUsers = users;
                    return state;
                });
            })
        );
    }
    @Action(ClearSearch)
    @ImmutableContext()
    clearSearch({ setState }: StateContext<AppStateModel>) {
        setState((state: AppStateModel) => {
            state.searchUsers = [];
            return state;
        });
    }
    @Action(GetNotifications)
    @ImmutableContext()
    getNotifications({ setState }: StateContext<AppStateModel>, action: GetNotifications) {
        return this.userService.notifications(action.page, action.unread).pipe(
            tap((notifications) => {
                setState((state: AppStateModel) => {
                    state.notifications = notifications.data;
                    state.notificationsPaginationMeta = notifications.meta;
                    return state;
                });
            })
        );
    }
    @Action(UpdateNotificationMarkAsRead)
    @ImmutableContext()
    updateNotificationMarkAsRead({ setState }: StateContext<AppStateModel>, action: UpdateNotificationMarkAsRead) {
        return this.userService.notificationsMarkAsRead(action.notification).pipe(
            tap((notifications) => {
                setState((state: AppStateModel) => {
                    state.notifications = notifications.data;
                    state.notificationsPaginationMeta = notifications.meta;
                    return state;
                });
            })
        );
    }
    @Action(GetNotificationSettings)
    @ImmutableContext()
    getNotificationSettings({ setState }: StateContext<AppStateModel>) {
        return this.userService.notificationSettings().pipe(
            tap((settings) => {
                setState((state: AppStateModel) => {
                    state.notificationSettings = settings;
                    return state;
                });
            })
        );
    }

    @Action(UpdateNotificationSettings)
    @ImmutableContext()
    updateNotificationSettings({ setState, getState }: StateContext<AppStateModel>, action: UpdateNotificationSettings) {
        setState((state: AppStateModel) => {
            const setting = state.notificationSettings.find((i) => i.notification === action.notification);
            if (action.value) {
                setting.channels.push(action.channel);
            } else {
                setting.channels = setting.channels.filter((i) => i !== action.channel);
            }

            return state;
        });
        const currentState = getState();
        return this.userService.updateNotificationSettings(currentState.notificationSettings).pipe(
            tap((settings) => {
                setState((state: AppStateModel) => {
                    state.notificationSettings = settings;
                    return state;
                });
            })
        );
    }
    @Action(GetExperts)
    @ImmutableContext()
    getExperts({ setState }: StateContext<AppStateModel>) {
        return this.userService.experts().pipe(
            tap((experts) => {
                setState((state: AppStateModel) => {
                    state.experts = experts;
                    return state;
                });
            })
        );
    }

    @Receiver()
    @ImmutableContext()
    public static getBanner({ setState }: StateContext<AppStateModel>) {
        return AppState.api.get(`banners`).pipe(
            map((response) => response.data),
            tap((result) => {
                setState((state: AppStateModel) => {
                    state.banner = result;
                    return state;
                });
            })
        );
    }

    @Receiver()
    @ImmutableContext()
    public static subscribe({ setState }: StateContext<AppStateModel>, { payload }: EmitterAction<number[]>) {
        return AppState.api.put(`profile/tags`, { tag_ids: payload }).pipe(
            map((response) => response.data),
            tap((result) => {
                setState((state: AppStateModel) => {
                    state.user = result;
                    return state;
                });
            })
        );
    }
    @Receiver()
    @ImmutableContext()
    public static unsubscribe({ setState }: StateContext<AppStateModel>, { payload }: EmitterAction<number>) {
        return AppState.api.delete(`profile/tags?tag_id=${payload}`).pipe(
            map((response) => response.data),
            tap((result) => {
                setState((state: AppStateModel) => {
                    state.user = result;
                    return state;
                });
            })
        );
    }
}
