import { Injectable } from "@angular/core";
import { Preferences } from "@capacitor/preferences";
import { StorageMap } from "@ngx-pwa/local-storage";

@Injectable({
    providedIn: "root",
})
export class AppLoaderService {
    public apiToken = null;
    public socketId = null;

    constructor(private storage: StorageMap) {}
    async init(): Promise<void> {
        return new Promise(async (resolve, reject) => {
            /*
            // localstorage is legacy
            // Unter iOS kann es passieren das der LocalStorage entfernt wird
            this.apiToken = await this.storage.get("api_token").toPromise();
              */
            // Wenn der LocalStorage leer ist, wird im Nativen Storage geschaut
            if (!this.apiToken) {
                this.apiToken = (await Preferences.get({ key: "api_token" })).value;
            }

            resolve();
        });
    }
    updateApiToken(apiToken: string | null) {
        this.apiToken = apiToken;
        if (apiToken == null) {
            Preferences.remove({ key: "api_token" });
        } else {
            Preferences.set({ key: "api_token", value: apiToken });
        }
        return this.storage.set("api_token", apiToken);
    }
}
