import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";

import { SupportGroupIndexRequest, SupportGroupRequest } from "../definition";
import { BaseService } from "./base.service";

@Injectable({
    providedIn: "root"
})
export class MapService {
    constructor(private baseService: BaseService) {}

    index(params: SupportGroupIndexRequest) {
        return this.baseService.post(`support-groups/${params.category}`, params).pipe(map(response => response.data));
    }
    detail(params: SupportGroupRequest) {
        return this.baseService.get(`support-groups/${params.category}/${params.id}`).pipe(map(response => response.data));
    }
}
