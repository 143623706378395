import { Injectable } from "@angular/core";
import { AlertController } from "@ionic/angular";
import { Select, Store } from "@ngxs/store";
import Echo from "laravel-echo";
import { Observable, BehaviorSubject } from "rxjs";

import { environment } from "../../environments/environment";
import { User } from "../definition";
import { AppState } from "../stores/app.state";
import { Router } from "@angular/router";
import { AddFriendRequest } from "../stores/app.action";
import { TranslateService } from "@ngx-translate/core";
import { ReceiveMessage } from "../stores/chat.actions";
import { AppLoaderService } from "./app-loader.service";

@Injectable({
    providedIn: "root",
})
export class SocketService {
    @Select(AppState.user) user$: Observable<User>;

    private connection = null;

    public connection$: BehaviorSubject<Echo> = new BehaviorSubject(null);

    constructor(private store: Store, private router: Router, private alertController: AlertController, private translate: TranslateService, private appLoader: AppLoaderService) {}
    init() {
        this.user$.subscribe((user) => {
            if (user === null && this.connection) {
                this.connection.disconnect();
            } else if (user && this.connection === null) {
                this.connect(user);
            }
        });
    }
    private connect(user: User) {
        this.connection = new Echo({
            broadcaster: "pusher",
            key: environment.pusher.key,
            cluster: environment.pusher.cluster,
            encrypted: true,
            authEndpoint: environment.apiEndpoint + "broadcasting/auth",
            auth: { headers: { Authorization: "Bearer " + user.api_token } },
        });

        this.connection.connector.pusher.connection.bind("connected", () => {
            this.appLoader.socketId = this.connection.socketId();
        });

        this.connection$.next(this.connection);

        this.connection.private("App.User." + user.id).notification((notification) => {
            if (notification.title) {
                this.presentAlert(notification.title, notification.body, notification.url);
            }

            if (notification.type === "App\\Notifications\\FriendRequest") {
                this.store.dispatch(new AddFriendRequest(notification.data.user));
            }
            if (notification.type === "App\\Notifications\\MessageSent") {
                this.store.dispatch(new ReceiveMessage(notification.chatMessage));
            }
        });
    }
    async presentAlert(header, message, url = null) {
        const alert = await this.alertController.create({
            header,
            message,
            buttons: [
                {
                    text: this.translate.instant("Ok"),
                    handler: () => {
                        if (url) {
                            this.router.navigateByUrl(url);
                        }
                    },
                },
            ],
        });

        await alert.present();
    }
}
