import { Injectable, Injector } from "@angular/core";
import { EmitterAction, Receiver } from "@ngxs-labs/emitter";
import { ImmutableContext } from "@ngxs-labs/immer-adapter";
import { Selector, State, StateContext } from "@ngxs/store";
import { map, tap } from "rxjs/operators";
import { Tag } from "../definition";
import { BaseService } from "../services/base.service";

export interface TagsStateModel {
    tags: Tag[];
    tag: Tag;
}

@State<TagsStateModel>({
    name: "tags",
    defaults: {
        tags: [],
        tag: null,
    },
})
@Injectable()
export class TagsState {
    private static api: BaseService;

    @Selector()
    static tags(state: TagsStateModel) {
        return state.tags;
    }

    @Selector()
    public static tag(state: TagsStateModel) {
        return state.tag;
    }

    constructor(injector: Injector) {
        TagsState.api = injector.get<BaseService>(BaseService);
    }

    @Receiver()
    @ImmutableContext()
    public static getTags({ setState }: StateContext<TagsStateModel>) {
        return TagsState.api.get(`tags`).pipe(
            map((response) => response.data),
            tap((result) => {
                setState((state: TagsStateModel) => {
                    state.tags = result;
                    return state;
                });
            })
        );
    }
    @Receiver()
    @ImmutableContext()
    public static clearTag({ setState }: StateContext<TagsStateModel>) {
        setState((state: TagsStateModel) => {
            state.tag = null;
            return state;
        });
    }
    @Receiver()
    @ImmutableContext()
    public static getTag({ setState }: StateContext<TagsStateModel>, { payload }: EmitterAction<number>) {
        return TagsState.api.get(`tags/${payload}`).pipe(
            map((response) => response.data),
            tap((result) => {
                setState((state: TagsStateModel) => {
                    state.tag = result;
                    return state;
                });
            })
        );
    }
}
