import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";

import { AppLoaderService } from "../services/app-loader.service";
import { Store } from "@ngxs/store";
import { AppState } from "../stores/app.state";

@Injectable({
    providedIn: "root",
})
export class LoginGuard implements CanActivate {
    private previousUrl: string = null;
    constructor(private router: Router, private appLoader: AppLoaderService, private store: Store) {}

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        const user = this.store.selectSnapshot(AppState.user);
        if (user && user.email_verified_at === null) {
            this.router.navigateByUrl("/verification");
            return false;
        }
        const privacy = this.store.selectSnapshot(AppState.privacy);
        if (privacy && user && new Date(privacy.published_at).getTime() > new Date(user.privacy_at).getTime()) {
            this.router.navigateByUrl("/privacy?isAcceptable=1");
            return false;
        }
        if (state.url === "/start") {
            if (this.appLoader.apiToken != null) {
                this.router.navigateByUrl("/");
                return false;
            } else {
                return true;
            }
        } else {
            if (this.appLoader.apiToken) {
                if (this.previousUrl) {
                    if (this.previousUrl !== state.url) {
                        this.router.navigateByUrl(this.previousUrl);
                    }
                    this.previousUrl = null;
                }
                return true;
            } else {
                // save the url to redirect after login
                this.previousUrl = state.url;
                this.router.navigateByUrl("/start");
                return false;
            }
        }
    }
}
