import { Injectable } from "@angular/core";
import "@capacitor-community/firebase-analytics";

import { environment } from "src/environments/environment";
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";

@Injectable({
    providedIn: "root",
})
export class TrackingService {
    constructor() {}
    init() {
        try {
            FirebaseAnalytics.initializeFirebase(environment.firebase);
        } catch (error) {}
    }
    setUserID(value: string) {
        try {
            FirebaseAnalytics.setUserId({
                userId: value,
            });
        } catch (error) {}
    }
    logEvent(name: string, params: any = {}) {
        try {
            FirebaseAnalytics.logEvent({
                name,
                params,
            });
        } catch (error) {}
    }
}
