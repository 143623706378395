import { Injectable, Injector } from '@angular/core';
import { EmitterAction, Receiver } from '@ngxs-labs/emitter';
import { ImmutableContext } from '@ngxs-labs/immer-adapter';
import { Selector, State, StateContext } from '@ngxs/store';

import { OnboardingUser } from '../definition';
import { BaseService } from '../services/base.service';

export interface OnboardingStateModel {
    user: OnboardingUser;
}

@State<OnboardingStateModel>({
    name: "onboarding",
    defaults: {
        user: null,
    },
})
@Injectable()
export class OnboardingState {
    private static api: BaseService;

    @Selector()
    static user(state: OnboardingStateModel) {
        return state.user;
    }

    constructor(injector: Injector) {
        OnboardingState.api = injector.get<BaseService>(BaseService);
    }

    @Receiver()
    @ImmutableContext()
    public static update({ setState }: StateContext<OnboardingStateModel>, { payload }: EmitterAction<OnboardingUser>) {
        setState((state: OnboardingStateModel) => {
            state.user = { ...state.user, ...payload };
            return state;
        });
    }
    @Receiver()
    @ImmutableContext()
    public static reset({ setState }: StateContext<OnboardingStateModel>, { payload }: EmitterAction) {
        setState((state: OnboardingStateModel) => {
            state.user = null;
            return state;
        });
    }
}
