import { Group, Post, Comment, GroupStoreRequest, User } from "../definition";

export class GroupsAction {
    public static readonly type = "[Groups] Add item";
    constructor(public payload: string) {}
}

export class GetGroups {
    public static readonly type = "[Groups] Get Groups";
    constructor(public page: number = 1, public query: string = "", public disease_id: number = null) {}
}
export class GetUserGroups {
    public static readonly type = "[Groups] Get User Groups";
    constructor() {}
}
export class ClearGroup {
    public static readonly type = "[Groups] Clear Group ";
    constructor() {}
}

export class SelectGroup {
    public static readonly type = "[Groups] Select Group";
    constructor(public group: Partial<Group>) {}
}
export class ClearPost {
    public static readonly type = "[Groups] Clear Post ";
    constructor() {}
}

export class SelectPost {
    public static readonly type = "[Groups] Select Post ";
    constructor(public group: Partial<Group>, public post: Partial<Post>) {}
}
export class CreatePost {
    public static readonly type = "[Groups] Create Post ";
    constructor(public group: Partial<Group>, public post: Partial<Post>) {}
}

export class DeletePost {
    public static readonly type = "[Groups] Delete Post ";
    constructor(public group: Partial<Group>, public post: Partial<Post>) {}
}
export class DeleteComment {
    public static readonly type = "[Groups] Delete Comment";
    constructor(public group: Partial<Group>, public post: Partial<Post>, public comment: Partial<Comment>) {}
}
export class ReceiveDeletedComment {
    public static readonly type = "[Groups] Receive Deleted Comment ";
    constructor(public commentId: number) {}
}
export class GetComments {
    public static readonly type = "[Groups] Get Comments ";
    constructor(public group: Partial<Group>, public post: Partial<Post>, public page: number = 1) {}
}

export class PostComment {
    public static readonly type = "[Groups] Post Comment ";
    constructor(public group: Partial<Group>, public post: Partial<Post>, public content: string, public comment: Partial<Comment> = null) {}
}

export class ReceiveComment {
    public static readonly type = "[Groups] Receive Comment ";
    constructor(public comment: Comment) {}
}
export class GetStream {
    public static readonly type = "[Groups] Get Stream ";
    constructor(public page: number = 1) {}
}
export class GetCategories {
    public static readonly type = "[Groups] Get Categories ";
    constructor() {}
}
export class CreateGroup {
    public static readonly type = "[Groups] Create Group ";
    constructor(public params: GroupStoreRequest) {}
}
export class UpdateGroup {
    public static readonly type = "[Groups] Update Group";
    constructor(public group: Partial<Group>, public params: GroupStoreRequest) {}
}

export class GetGroupPosts {
    public static readonly type = "[Groups] Get Group Posts";
    constructor(public group: Partial<Group>, public page: number = 1, public params: any = {}) {}
}
export class GetVideoPosts {
    public static readonly type = "[Dashboard] Get video posts from group 1";
    constructor() {}
}
export class GetGroupMembers {
    public static readonly type = "[Groups] Get Group Members";
    constructor(public group: Partial<Group>, public page: number = 1) {}
}
export class GetGroupMemberRequests {
    public static readonly type = "[Groups] Get Group Member Requests";
    constructor(public group: Partial<Group>) {}
}

export class JoinGroup {
    public static readonly type = "[Groups] Join Group";
    constructor(public group: Partial<Group>) {}
}
export class LeaveGroup {
    public static readonly type = "[Groups] Leave Group";
    constructor(public group: Partial<Group>) {}
}
export class RequestGroup {
    public static readonly type = "[Groups] Request Group";
    constructor(public group: Partial<Group>) {}
}

export class RemoveGroupMember {
    public static readonly type = "[Groups] Remove Group Member";
    constructor(public group: Partial<Group>, public user: Partial<User>) {}
}
export class RemoveGroupAdmin {
    public static readonly type = "[Groups] Remove Group Admin";
    constructor(public group: Partial<Group>, public user: Partial<User>) {}
}
export class AddGroupAdmin {
    public static readonly type = "[Groups] Add Group Admin";
    constructor(public group: Partial<Group>, public user: Partial<User>) {}
}

export class AddGroupMembers {
    public static readonly type = "[Groups] Add Group Members";
    constructor(public group: Partial<Group>, public users: User[]) {}
}

export class AcceptMemberRequest {
    public static readonly type = "[Groups] Accept Member Request";
    constructor(public group: Partial<Group>, public user: User) {}
}
export class RejectMemberRequest {
    public static readonly type = "[Groups] Reject Member Request";
    constructor(public group: Partial<Group>, public user: User) {}
}

export class SearchGroups {
    static readonly type = "[Groups] Set Search";
    constructor(public q: string) {}
}
export class ClearGroupSearch {
    static readonly type = "[App] Clear Search";
    constructor() {}
}

export class SearchGroupPosts {
    static readonly type = "[Groups] Set Search";
    constructor(public q: string) {}
}
export class ClearGroupPostSearch {
    static readonly type = "[App] Clear Search";
    constructor() {}
}
