import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";

import { GroupAdminGuard } from "./guards/group-admin.guard";
import { LoginGuard } from "./guards/login.guard";

const routes: Routes = [
    {
        path: "",
        canActivate: [],
        loadChildren: () => import("./pages/tabs/tabs.module").then((m) => m.TabsPageModule),
    },
    {
        path: "onboarding",
        loadChildren: () => import("./pages/onboarding/onboarding.module").then((m) => m.OnboardingPageModule),
    },
    {
        path: "login",
        canActivate: [],
        loadChildren: () => import("./pages/login/login.module").then((m) => m.LoginPageModule),
    },
    {
        path: "start",
        canActivate: [LoginGuard],
        loadChildren: () => import("./pages/start/start.module").then((m) => m.StartPageModule),
    },

    {
        path: "register",
        loadChildren: () => import("./pages/registration/registration.module").then((m) => m.RegistrationPageModule),
    },
    {
        path: "password-reset",
        loadChildren: () => import("./pages/password-reset/password-reset.module").then((m) => m.PasswordResetPageModule),
    },
    {
        path: "password-reset/:token",
        loadChildren: () => import("./pages/password-reset/password-reset.module").then((m) => m.PasswordResetPageModule),
    },
    {
        path: "verification",
        loadChildren: () => import("./pages/verification/verification.module").then((m) => m.VerificationPageModule),
    },
    {
        path: "verification-success",
        loadChildren: () => import("./pages/verification-success/verification-success.module").then((m) => m.VerificationSuccessPageModule),
    },
    {
        path: "conversation/:uuid",
        canActivate: [LoginGuard],
        loadChildren: () => import("./pages/chat-conversation/chat-conversation.module").then((m) => m.ChatConversationPageModule),
    },
    {
        path: "user/:uuid",
        canActivate: [LoginGuard],
        loadChildren: () => import("./pages/user-detail/user-detail.module").then((m) => m.UserDetailPageModule),
    },
    {
        path: "privacy",
        loadChildren: () => import("./pages/privacy/privacy.module").then((m) => m.PrivacyPageModule),
    },
    {
        path: "tour",
        loadChildren: () => import("./pages/tour/tour.module").then((m) => m.TourPageModule),
    },
    {
        path: "groups/:id/posts/create",
        canActivate: [LoginGuard],
        loadChildren: () => import("./pages/post-form/post-form.module").then((m) => m.PostFormPageModule),
    },
    {
        path: "groups/:id/posts/:postId",
        canActivate: [LoginGuard],
        loadChildren: () => import("./pages/post/post.module").then((m) => m.PostPageModule),
    },
    {
        path: "groups/create",
        canActivate: [LoginGuard],
        loadChildren: () => import("./pages/groups-form/groups-form.module").then((m) => m.GroupsFormPageModule),
    },
    {
        path: "groups/filter",
        canActivate: [LoginGuard],
        loadChildren: () => import("./pages/groups-filter/groups-filter.module").then((m) => m.GroupsFilterPageModule),
    },
    {
        path: "groups/:id",
        canActivate: [LoginGuard],
        loadChildren: () => import("./pages/group-detail/group-detail.module").then((m) => m.GroupDetailPageModule),
    },
    {
        path: "groups/:id/edit",
        canActivate: [LoginGuard, GroupAdminGuard],
        loadChildren: () => import("./pages/group-edit/group-edit.module").then((m) => m.GroupEditPageModule),
    },
    {
        path: "groups/:id/select-friends",
        canActivate: [LoginGuard, GroupAdminGuard],
        loadChildren: () => import("./pages/select-friends/select-friends.module").then((m) => m.SelectFriendsPageModule),
    },
    {
        path: "netiquette",
        loadChildren: () => import("./pages/netiquette/netiquette.module").then((m) => m.NetiquettePageModule),
    },
    {
        path: "register-help",
        loadChildren: () => import("./pages/registration-help/registration-help.module").then((m) => m.RegistrationHelpPageModule),
    },
    {
        path: "informations",
        canActivate: [LoginGuard],
        loadChildren: () => import("./pages/informations/informations.module").then((m) => m.InformationsPageModule),
    },
    {
        path: "map",
        loadChildren: () => import("./pages/map/map.module").then((m) => m.MapPageModule),
    },
    {
        path: "my-info",
        loadChildren: () => import("./pages/my-info/my-info.module").then((m) => m.MyInfoPageModule),
    },
    {
        path: "tags",
        loadChildren: () => import("./pages/tags/tags.module").then((m) => m.TagsPageModule),
    },
    {
        path: "tags/:id",
        loadChildren: () => import("./pages/tag/tag.module").then((m) => m.TagPageModule),
    },
    {
        path: "profile-update",
        loadChildren: () => import("./pages/profile-update/profile-update.module").then((m) => m.ProfileUpdatePageModule),
    },
];
@NgModule({
    imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: "legacy" })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
