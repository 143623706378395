import { Injectable } from "@angular/core";
import { ImmutableContext } from "@ngxs-labs/immer-adapter";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { patch, updateItem } from "@ngxs/store/operators";
import { tap } from "rxjs/operators";

import { Message, PaginationMeta } from "../definition";
import { ChatService } from "../services/chat.service";
import { AppState, AppStateModel } from "./app.state";
import { ClearConversation, DeleteConversation, GetConversation, GetConversations, MarkAsReadMessage, ReceiveMessage, SendMessage } from "./chat.actions";

interface ChatStateModel {
    conversations: Message[];
    selectedConversation: Message[];
    paginationMeta: PaginationMeta;
}

@State<ChatStateModel>({
    name: "chat",
    defaults: {
        conversations: [],
        selectedConversation: null,
        paginationMeta: null,
    },
})
@Injectable()
export class ChatState {
    @Selector()
    static conversations(state: ChatStateModel) {
        return state.conversations;
    }
    @Selector()
    static selectedConversation(state: ChatStateModel) {
        return state.selectedConversation;
    }
    @Selector()
    static paginationMeta(state: ChatStateModel) {
        return state.paginationMeta;
    }

    constructor(private chat: ChatService) {}

    @Action(ClearConversation)
    @ImmutableContext()
    clearConversation({ setState }: StateContext<ChatStateModel>) {
        setState((state: ChatStateModel) => {
            state.selectedConversation = null;
            state.paginationMeta = null;
            return state;
        });
    }
    @Action(GetConversations)
    @ImmutableContext()
    GetConversations({ setState }: StateContext<ChatStateModel>) {
        return this.chat.conversations().pipe(
            tap((conversations) => {
                setState((state: ChatStateModel) => {
                    state.conversations = conversations;
                    return state;
                });
            })
        );
    }
    @Action(DeleteConversation)
    @ImmutableContext()
    DeleteConversation({ setState }: StateContext<ChatStateModel>, action: DeleteConversation) {
        return this.chat.deleteConversation(action.user);
    }
    @Action(GetConversation)
    getConversation({ setState, getState }: StateContext<ChatStateModel>, action: GetConversation) {
        const currentState = getState();

        return this.chat.conversation(action.user, action.page).pipe(
            tap((conversation) => {
                if (action.page === 1) {
                    setState(
                        patch({
                            selectedConversation: conversation.data.reverse(),
                            paginationMeta: conversation.meta,
                        })
                    );
                } else {
                    const merged = [...conversation.data.reverse(), ...currentState.selectedConversation];
                    setState(
                        patch({
                            selectedConversation: merged,
                            paginationMeta: conversation.meta,
                        })
                    );
                }
            })
        );
    }

    @Action(SendMessage)
    @ImmutableContext()
    sendMessage({ setState }: StateContext<ChatStateModel>, action: SendMessage) {
        return this.chat.sendMessage({ body: action.body, user: action.user, type: action.messageType }).pipe(
            tap((message) => {
                setState((state: ChatStateModel) => {
                    state.selectedConversation.push(message);
                    return state;
                });
            })
        );
    }
    @Action(MarkAsReadMessage)
    markAsReadMessage({ setState }: StateContext<ChatStateModel>, action: MarkAsReadMessage) {
        return this.chat.markAsRead(action.user).pipe(
            tap((messages) => {
                setState(
                    patch({
                        conversations: updateItem<Message>(
                            (item) => item.receiver.uuid === action.user.uuid || item.sender.uuid === action.user.uuid,
                            patch({ read_at: new Date().toString() })
                        ),
                    })
                );
            })
        );
    }

    @Action(ReceiveMessage)
    @ImmutableContext()
    receiveMessage({ setState, getState }: StateContext<ChatStateModel>, action: ReceiveMessage) {
        const currentState = getState();
        if (currentState.selectedConversation && currentState.selectedConversation.length !== 0 && currentState.selectedConversation[0].group === action.message.group) {
            setState((state: ChatStateModel) => {
                state.selectedConversation.push(action.message);
                return state;
            });
        }
        const index = currentState.conversations.findIndex((item) => item.group === action.message.group);
        if (index === -1) {
            setState((state: ChatStateModel) => {
                state.conversations.push(action.message);
                return state;
            });
        } else {
            setState((state: ChatStateModel) => {
                state.conversations[index] = action.message;
                return state;
            });
        }
    }
}

export class ChatStateService {
    /**
     *  Um die ungelesenen Conversationen zu finden - brauchen wir beide STATES Chat und User
     */
    @Selector([ChatState, AppState])
    static hasUnreadMessages(state: ChatStateModel, appState: AppStateModel): boolean {
        return state.conversations.find((item) => item.read_at === null && appState.user.uuid === item.receiver.uuid) ? true : false;
    }
}
