import {
    LoginRequest,
    Notification,
    OnboardingUser,
    ResetPasswordRequest,
    SupportGroupIndexRequest,
    SupportGroupRequest,
    User,
} from '../definition';

export class RegisterUser {
    static readonly type = "[API] Register";
    constructor(public params: OnboardingUser) {}
}
export class SendEmailVerification {
    static readonly type = "[API] send email verification";
    constructor() {}
}

export class VerifyEmail {
    static readonly type = "[API] verify email";
    constructor(public params: any) {}
}

export class SendPasswordResetLink {
    static readonly type = "[API] Password Reset Link";
    constructor(public email: string) {}
}
export class ResetPassword {
    static readonly type = "[API] Password Reset ";
    constructor(public params: ResetPasswordRequest) {}
}

export class UserLogout {
    static readonly type = "[API] Logout";
    constructor() {}
}

export class DisableNotifications {
    static readonly type = "[API] DELETE Push Token";
    constructor() {}
}

export class UserLogin {
    static readonly type = "[API] Login";
    constructor(public params: LoginRequest) {}
}

export class GetUser {
    static readonly type = "[API] Get User";
    constructor() {}
}
export class DeleteUser {
    static readonly type = "[API] DeleteUser";
}

export class ClearSelectedUser {
    static readonly type = "[API] Clear Select User";
    constructor() {}
}

export class SelectUser {
    static readonly type = "[API] Select User";
    constructor(public user: Partial<User>) {}
}
export class UpdateUser {
    static readonly type = "[API] Update User";
    constructor(public params: Partial<OnboardingUser>) {}
}

export class GetPrivacy {
    static readonly type = "[API] GET Privacy";
    constructor() {}
}
export class AcceptPrivacy {
    static readonly type = "[API] Accept Privacy";
    constructor() {}
}

/** ---------------------------------------- */
/**
 * Other
 */
export class GetDiseases {
    static readonly type = "[API] Get Diseases";
    constructor() {}
}

export class GetInformations {
    static readonly type = "[API] Get Informations";
    constructor(public query: string = null, public diseaseId: number = null) {}
}

/** ---------------------------------------- */
/**
 * Friends
 */
export class GetFriends {
    static readonly type = "[API] Get Friends";
    constructor() {}
}
export class GetFriendRequests {
    static readonly type = "[API] Get Friend Requests";
    constructor() {}
}
export class AcceptFriendRequest {
    static readonly type = "[API] Accept Friend Request";
    constructor(public user: User) {}
}
export class DenyFriendRequest {
    static readonly type = "[API] Deny Friend Request";
    constructor(public user: User) {}
}
export class AddFriend {
    static readonly type = "[API] Send Friend Request";
    constructor(public user: User) {}
}
export class AddFriendRequest {
    static readonly type = "[Notification] Add an incoming friend request";
    constructor(public user: User) {}
}

/** ---------------------------------------- */
/**
 * Favoriten
 */
export class GetFavorites {
    static readonly type = "[API] Get Favorites";
    constructor() {}
}
export class RemoveFavorit {
    static readonly type = "[API] Remove user from favorites";
    constructor(public user: User) {}
}
export class AddFavorit {
    static readonly type = "[API] Add User to favorites";
    constructor(public user: User) {}
}

/** ---------------------------------------- */
/**
 * Vorschläge
 */
export class ExploreUsers {
    static readonly type = "[API] Explore";
    constructor(public page: number = 1) {}
}

export class NewUsers {
    static readonly type = "[API] New Users";
    constructor() {}
}

export class SearchUsers {
    static readonly type = "[API] Search User";
    constructor(public q: string) {}
}
export class ClearSearch {
    static readonly type = "[App] Clear Search";
    constructor() {}
}

export class BlockUser {
    static readonly type = "[API] Block User";
    constructor(public user: User) {}
}
export class ReportUser {
    static readonly type = "[API] Report User";
    constructor(public reportableId: string|number, public reportableType: string, public comment: string) {}
}

/** ---------------------------------------- */
/**
 * Beratungsgruppen / Selbsthilfe
 */
export class ClearSupportGroups {
    static readonly type = "[App] Clear Support Groups";
    constructor() {}
}
export class ClearSelectedSupportGroup {
    static readonly type = "[App] Clear Selected Group";
    constructor() {}
}
export class GetSupportGroup {
    static readonly type = "[API] Get Support Group By Id";
    constructor(public params: SupportGroupRequest) {}
}
export class GetSupportGroups {
    static readonly type = "[API] Get Support Groups";
    constructor(public params: SupportGroupIndexRequest) {}
}

export class GetNotifications {
    static readonly type = "[API] Get User Notifications";
    constructor(public page: number = 1, public unread: boolean = true) {}
}
export class GetNotificationSettings {
    static readonly type = "[API] Get Notification Settings";
    constructor() {}
}
export class UpdateNotificationMarkAsRead {
    static readonly type = "[API] Update Notification Mark as Read";
    constructor(public notification: Notification = null) {}
}
export class UpdateNotificationSettings {
    static readonly type = "[API] Update Notification Settings";
    constructor(public notification: string, public channel: string, public value: boolean) {}
}

export class GetExperts {
    static readonly type = "[API] GetExperts";
}
