import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from "@angular/router";
import { Observable } from "rxjs";
import { Store } from "@ngxs/store";
import { GroupsState } from "../stores/groups.state";
import { first } from "rxjs/operators";

@Injectable({
    providedIn: "root",
})
export class GroupAdminGuard {
    constructor(private router: Router, private store: Store) {}
    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return new Promise<boolean>((resolve, reject) => {
            this.store
                .select(GroupsState.selectedGroup)
                .pipe(first())
                .subscribe((group) => {
                    if (group.is_admin) {
                        resolve(true);
                    } else {
                        this.router.navigateByUrl("/groups/" + group.id);
                        resolve(false);
                    }
                });
        });
    }
}
