import { NgModule } from "@angular/core";
import { TruncatePipe } from "./truncate";
import { StagePipe } from "./stage.pipe";
import { MessageUserPipe } from "./message-user.pipe";
import { SafePipe } from "./safe.pipe";

@NgModule({
    declarations: [TruncatePipe, StagePipe, MessageUserPipe, SafePipe],
    imports: [],
    exports: [TruncatePipe, StagePipe, MessageUserPipe, SafePipe]
})
export class PipesModule {}
