export const environment = {
    name: "production",
    production: true,
    apiEndpoint: "https://api.yeswecan-cer.org/v2/",
    appUrl: "https://web.yeswecan-cer.org/",
    firebase: {
        apiKey: "AIzaSyDLeZeQ_XcsRfM3Y3MFlDYe9jpRm2UoGcw",
        authDomain: "yeswecan-cer.firebaseapp.com",
        databaseURL: "https://yeswecan-cer.firebaseio.com",
        projectId: "yeswecan-cer",
        storageBucket: "yeswecan-cer.appspot.com",
        messagingSenderId: "962583774897",
        appId: "1:962583774897:web:0b9cd7b93b40555d63c490",
        measurementId: "G-GJ8YEP0HEG",
    },
    pusher: {
        key: "984a9a768eaf2be32121",
        cluster: "eu",
        encrypted: true,
    },
};
