import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RequiredIfDirective } from "./required-if.directive";
import { FormSubmitDirective } from "./form-submit.directive";
import { ControlErrorComponent } from "../components/control-error/control-error.component";
import { ControlErrorContainerDirective } from "./control-error-container.directive";
import { ControlErrorsDirective } from "./control-errors.directive";
import { AutoresizeDirective } from "./autoresize.directive";
import { ShareableDirective } from './shareable.directive';

@NgModule({
    declarations: [RequiredIfDirective, FormSubmitDirective, ControlErrorContainerDirective, ControlErrorsDirective, AutoresizeDirective, ShareableDirective],
    exports: [RequiredIfDirective, FormSubmitDirective, ControlErrorContainerDirective, ControlErrorsDirective, AutoresizeDirective, ShareableDirective],
    imports: [CommonModule],
})
export class DirectivesModule {}
