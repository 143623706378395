import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { ToastController } from "@ionic/angular";
import { NgProgress, NgProgressRef } from "@ngx-progressbar/core";
import { Observable, throwError } from "rxjs";
import { catchError, finalize, map } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { AppLoaderService } from "./app-loader.service";
import { APPMeta, AppError } from "../definition";
import { TranslateService } from "@ngx-translate/core";
import { SocketService } from "./socket.service";

@Injectable({
    providedIn: "root",
})
export class BaseService {
    constructor(
        private http: HttpClient,
        private router: Router,
        private toastCtrl: ToastController,
        private progress: NgProgress,
        private appLoader: AppLoaderService,
        private translate: TranslateService
    ) {}
    uploadMedia(file): Observable<any> {
        const progressRef: NgProgressRef = this.progress.ref();
        progressRef.start();

        const formData = new FormData();
        formData.append("file", file, file.name);

        const headers = new HttpHeaders().set("Accept", "application/json").set("authorization", "Bearer " + this.appLoader.apiToken);

        return this.http.post(environment.apiEndpoint + "media", formData, { headers, reportProgress: true }).pipe(
            map((response: any) => response.data),
            catchError((error) => {
                progressRef.complete();
                return this.handleError(error);
            }),
            finalize(() => {
                progressRef.complete();
            })
        );
    }
    get(url): Observable<any> {
        const progressRef: NgProgressRef = this.progress.ref();
        progressRef.start();

        return this.http.get(environment.apiEndpoint + url, { headers: this.getHeaders() }).pipe(
            map((res: Response) => {
                return this.handleResponse(res);
            }),
            catchError((error) => {
                return this.handleError(error);
            }),
            finalize(() => {
                progressRef.complete();
            })
        );
    }

    post(url, params): Observable<any> {
        const progressRef: NgProgressRef = this.progress.ref();
        progressRef.start();
        return this.http
            .post(environment.apiEndpoint + url, params, {
                headers: this.getHeaders(),
            })
            .pipe(
                map((res: Response) => {
                    return this.handleResponse(res);
                }),
                catchError((error) => {
                    return this.handleError(error);
                }),
                finalize(() => {
                    progressRef.complete();
                })
            );
    }

    delete(url): Observable<any> {
        const progressRef: NgProgressRef = this.progress.ref();
        progressRef.start();
        return this.http.delete(environment.apiEndpoint + url, { headers: this.getHeaders() }).pipe(
            map((res: Response) => {
                return this.handleResponse(res);
            }),
            catchError((error) => {
                return this.handleError(error);
            }),
            finalize(() => {
                progressRef.complete();
            })
        );
    }

    put(url, params) {
        const progressRef: NgProgressRef = this.progress.ref();
        progressRef.start();
        return this.http
            .put(environment.apiEndpoint + url, params, {
                headers: this.getHeaders(),
            })
            .pipe(
                map((res: Response) => {
                    return this.handleResponse(res);
                }),
                catchError((error) => {
                    return this.handleError(error);
                }),
                finalize(() => {
                    progressRef.complete();
                })
            );
    }

    private handleError(error) {
        if (error.status === 401) {
            this.appLoader
                .updateApiToken(null)
                .toPromise()
                .then(() => {
                    this.router.navigateByUrl("/");
                });
        } else if (error.status === 404) {
            error.error.message = this.translate.instant("Die gesuchte Ressource konnte leider nicht gefunden werden.");
        } else if (error.status === 400) {
            return throwError(<AppError>error.error);
        } else if (error.status === 422) {
            error.error.message = this.translate.instant("Fehler bei der Eingabe") + " ";
            for (const errorKey in error.error.errors) {
                const errors = error.error.errors[errorKey];
                error.error.message += errors.join();
            }
        }

        this.toastCtrl
            .create({
                message: error.error.message,
                color: "danger",
                duration: 3000,
            })
            .then((toast) => {
                toast.present();
            });

        return throwError(error);
    }
    private handleResponse(res: Response): any {
        return res;
    }
    private getHeaders(): HttpHeaders {
        let language = navigator.language;
        if (language.indexOf("-") !== -1) {
            language = language.split("-")[0];
        }

        if (language.indexOf("_") !== -1) {
            language = language.split("_")[0];
        }

        let headers = new HttpHeaders()
            .set("Content-Type", "application/json")
            .set("X-Localization", language)
            .set("Accept-Language", language)
            .set("X-App-Version", APPMeta.VERSION);

        if (this.appLoader.apiToken != null) {
            headers = headers.append("authorization", `Bearer ${this.appLoader.apiToken}`);
        }

        if (this.appLoader.socketId) {
            headers = headers.append("X-Socket-ID", this.appLoader.socketId);
        }

        return headers;
    }
}
