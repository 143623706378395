import { Injectable } from "@angular/core";
import { BaseService } from "./base.service";
import { Observable } from "rxjs";
import { Group, Post, Comment, PaginationResult, GroupStoreRequest, User, GroupRole, GroupStatus, GroupsGetRequest } from "../definition";
import { map } from "rxjs/operators";
import { UrlSerializer, Router } from "@angular/router";

@Injectable({
    providedIn: "root",
})
export class GroupsService {
    constructor(private baseService: BaseService, private serializer: UrlSerializer, private router: Router) {}

    groups(params: GroupsGetRequest): Observable<PaginationResult<Group[]>> {
        const tree = this.router.createUrlTree([], { queryParams: params });
        const url = this.serializer.serialize(tree).split("?");
        const queryParams = url.length === 2 ? url[1] : "";

        return this.baseService.get(`groups?${queryParams}`);
    }
    userGroups(): Observable<Group[]> {
        return this.baseService.get("profile/groups").pipe(map((response) => response.data));
    }

    group(group: Partial<Group>): Observable<Group> {
        return this.baseService.get("groups/" + group.id).pipe(map((response) => response.data));
    }
    post(group: Partial<Group>, post: Partial<Post>): Observable<Post> {
        return this.baseService.get(`groups/${group.id}/posts/${post.id}`).pipe(map((response) => response.data));
    }
    comments(group: Partial<Group>, post: Partial<Post>, page: number = 1): Observable<PaginationResult<Comment[]>> {
        return this.baseService.get(`groups/${group.id}/posts/${post.id}/comments?page=${page}`);
    }
    sendComment(group: Partial<Group>, post: Partial<Post>, content: string, comment: Partial<Comment> = null): Observable<Comment> {
        return this.baseService
            .post(`groups/${group.id}/posts/${post.id}/comments`, {
                content,
                comment_id: comment?.id,
            })
            .pipe(map((response) => response.data));
    }

    categories(): Observable<Post[]> {
        return this.baseService.get("group-categories").pipe(map((response) => response.data));
    }

    create(params: GroupStoreRequest): Observable<Group> {
        return this.baseService.post("groups", params).pipe(map((response) => response.data));
    }
    update(group: Partial<Group>, params: GroupStoreRequest): Observable<Group> {
        return this.baseService.put(`groups/${group.id}`, params).pipe(map((response) => response.data));
    }
    groupPosts(group: Partial<Group>, page: number = 1, queryParams: any): Observable<PaginationResult<Post[]>> {
        const tree = this.router.createUrlTree([], { queryParams });
        const url = this.serializer.serialize(tree).split("?");
        queryParams = url.length === 2 ? url[1] : "";
        return this.baseService.get(`groups/${group.id}/posts?page=${page}&${queryParams}`);
    }
    groupMembers(group: Partial<Group>, page: number = 1): Observable<PaginationResult<User[]>> {
        return this.baseService.get(`groups/${group.id}/members?page=${page}`);
    }
    groupMemberRequests(group: Partial<Group>): Observable<User[]> {
        return this.baseService.get(`groups/${group.id}/member-requests`).pipe(map((response) => response.data));
    }
    join(group: Partial<Group>): Observable<Group> {
        return this.baseService.post(`groups/${group.id}/members`, null).pipe(map((response) => response.data));
    }
    leave(group: Partial<Group>, user: Partial<User>): Observable<Group> {
        return this.baseService.delete(`groups/${group.id}/members/${user.id}`).pipe(map((response) => response.data));
    }
    addAdmin(group: Partial<Group>, user: Partial<User>): Observable<Group> {
        return this.baseService.put(`groups/${group.id}/members/${user.id}`, { role: GroupRole.Admin }).pipe(map((response) => response.data));
    }
    addMembers(group: Partial<Group>, users: User[]): Observable<Group> {
        users = Object.keys(users).map((f) => users[f].id);
        return this.baseService.post(`groups/${group.id}/members`, { users }).pipe(map((response) => response.data));
    }

    removeAdmin(group: Partial<Group>, user: Partial<User>): Observable<Group> {
        return this.baseService.put(`groups/${group.id}/members/${user.id}`, { role: GroupRole.Member }).pipe(map((response) => response.data));
    }
    updateMemberRequest(group: Partial<Group>, user: Partial<User>, status: GroupStatus): Observable<Group> {
        return this.baseService.put(`groups/${group.id}/member-requests/${user.id}`, { status }).pipe(map((response) => response.data));
    }

    createPost(group: Partial<Group>, post: Partial<Post>) {
        return this.baseService.post(`groups/${group.id}/posts`, post).pipe(map((response) => response.data));
    }
    deletePost(group: Partial<Group>, post: Partial<Post>) {
        return this.baseService.delete(`groups/${group.id}/posts/${post.id}`);
    }
    deleteComment(group: Partial<Group>, post: Partial<Post>, comment: Partial<Comment>) {
        return this.baseService.delete(`groups/${group.id}/posts/${post.id}/comments/${comment.id}`);
    }
    stream(page: number = 1): Observable<PaginationResult<Post[]>> {
        return this.baseService.get(`stream?page=${page}`);
    }
}
