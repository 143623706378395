import { Injectable } from "@angular/core";
import { BaseService } from "./base.service";
import { Observable } from "rxjs";
import { MessageRequest, Message, User, Conversation } from "../definition";
import { map } from "rxjs/operators";

@Injectable({
    providedIn: "root",
})
export class ChatService {
    constructor(private baseService: BaseService) {}

    conversations(): Observable<Message[]> {
        return this.baseService.get("profile/messages").pipe(map((response) => response.data));
    }
    deleteConversation(user: Partial<User>): Observable<Conversation> {
        return this.baseService.delete("messages/" + user.uuid);
    }
    conversation(user: Partial<User>, page: number = 1): Observable<Conversation> {
        return this.baseService.get("messages/" + user.uuid + "?page=" + page);
    }
    markAsRead(user: Partial<User>): Observable<Message[]> {
        return this.baseService.post("messages/" + user.uuid + "/read", null).pipe(map((response) => response.data));
    }

    sendMessage(params: MessageRequest): Observable<Message> {
        return this.baseService
            .post(`messages/${params.user.uuid}`, {
                type: params.type,
                body: params.body,
            })
            .pipe(map((response) => response.data));
    }
}
